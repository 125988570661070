import { z } from 'zod';
import { MedtechRoles } from '../../types/medtechRole.types';
import { readOnly, normal, quality } from '../../__stringConstants__/qualioConstants';
import {
  CanCreateIssue,
  CanManageQualityEventWorkflow,
  CanViewQE,
  CanWorkOnIssue,
  CanAccessSuppliers,
  CanAccessBilling,
  CanAccessAPICapabilities,
  CanAccessDesignControls,
} from './medtechPermissions';

export const ManageablePermissionsEnumSchema = z.enum([
  CanAccessBilling,
  CanViewQE,
  CanCreateIssue,
  CanWorkOnIssue,
  CanManageQualityEventWorkflow,
  CanAccessSuppliers,
  CanAccessAPICapabilities,
  CanAccessDesignControls,
]);

export const MedtechPermissionSchema = z.object({
  permission_id: z.string(),
  label: z.string().nullable(),
});

export const MedtechPermissionSchemaStrictEnum = MedtechPermissionSchema.extend({
  permission_id: ManageablePermissionsEnumSchema,
});

export const MedtechPermissionsByRoleSchema = z.object({
  userGroup: z.enum(MedtechRoles),
  permissions: z.array(MedtechPermissionSchema).transform((arg) => {
    const filter = arg.filter((a) => ManageablePermissionsEnumSchema.safeParse(a.permission_id).success);
    return filter as z.infer<typeof MedtechPermissionSchemaStrictEnum>[];
  }),
});

export const MedtechPermissionsByRolesSchema = z.array(MedtechPermissionsByRoleSchema);

export const MedtechPermissionsByRolesMapSchema = z.object({
  [readOnly]: z.array(ManageablePermissionsEnumSchema),
  [normal]: z.array(ManageablePermissionsEnumSchema),
  [quality]: z.array(ManageablePermissionsEnumSchema),
});

export type MedtechPermission = z.infer<typeof MedtechPermissionSchema>;
export type MedtechPermissionByRole = z.infer<typeof MedtechPermissionsByRoleSchema>;
export type MedtechPermissionsByRoles = z.infer<typeof MedtechPermissionsByRolesSchema>;
export type MedtechPermissionsByRolesMap = z.infer<typeof MedtechPermissionsByRolesMapSchema>;

export const validateMedtechPermissions = (permissions: MedtechPermissionsByRoles) =>
  MedtechPermissionsByRolesSchema.parse(permissions);

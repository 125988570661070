import React from 'react';
import { QModal, QModalHeader, QText, QModalBody, QModalActions, QButton } from '@qualio/ui-components';
import {
  DenyUpdatePermissionsButtonLabel,
  ShouldUpdateUserPermissionsModalDescription,
  ShouldUpdateUserPermissionsModalHeader,
  UpdatePermissionsButtonLabel,
} from './__displayStrings__/ResetUserPermissionsModal';

type ResetPermissionModalProps = {
  resetPermissions: () => void;
  closeModal: () => void;
};

const ResetUserPermissionsModal = ({ closeModal, resetPermissions }: ResetPermissionModalProps) => {
  const submitClick = () => {
    resetPermissions();
    closeModal();
  };

  return (
    <QModal onClose={closeModal} size="md" isOpen>
      <QModalHeader>
        <QText data-cy="heading">{ShouldUpdateUserPermissionsModalHeader}</QText>
      </QModalHeader>
      <QModalBody>
        <QText>{ShouldUpdateUserPermissionsModalDescription}</QText>
      </QModalBody>
      <QModalActions>
        <QButton data-cy="no-button" variant="outline" onClick={closeModal}>
          {DenyUpdatePermissionsButtonLabel}
        </QButton>
        <QButton data-cy="yes-button" onClick={submitClick}>
          {UpdatePermissionsButtonLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ResetUserPermissionsModal;

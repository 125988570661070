import { useAnalytics, useCurrentUser as QCurrentUser } from '@qualio/ui-components';
import { TrackedEvents } from './events';
import { UmAnalytics, UmAnalyticEvents, EditUserQEPermissionsEventParams } from './types';

export function useUmAnalytics(): UmAnalytics {
  const analytics = useAnalytics();
  const { companyId, userId } = QCurrentUser();
  const baseParams = { companyId, userId, groupId: companyId };
  const combineParams = (params?: object) => ({ ...baseParams, ...params });

  const track = (message: UmAnalyticEvents, params?: object): void => analytics.track(message, combineParams(params));

  const inviteUserSuccess = (isAdmin: boolean, params?: object): void => {
    const event = isAdmin ? TrackedEvents.INVITE_ADMIN_SUCCESS : TrackedEvents.INVITE_USER_SUCCESS;
    analytics.track(event, combineParams(params));
  };

  const inviteUserFailed = (isAdmin: boolean, params?: object): void => {
    const event = isAdmin ? TrackedEvents.INVITE_ADMIN_FAILED : TrackedEvents.INVITE_USER_FAILED;
    analytics.track(event, combineParams(params));
  };

  const removeUserSuccess = (params?: object): void =>
    analytics.track(TrackedEvents.REMOVE_USER_SUCCESS, combineParams(params));

  const removeUserFailed = (params?: object): void =>
    analytics.track(TrackedEvents.REMOVE_USER_FAILED, combineParams(params));

  const cancelUserSuccess = (params?: object): void =>
    analytics.track(TrackedEvents.CANCEL_INVITATION_SUCCESS, combineParams(params));

  const cancelUserFailed = (params?: object): void =>
    analytics.track(TrackedEvents.CANCEL_INVITATION_FAILED, combineParams(params));

  const featureToggledSuccess = (isEnabled: boolean, params: object): void => {
    const event = isEnabled ? TrackedEvents.FEATURE_ENABLED_SUCCESS : TrackedEvents.FEATURE_DISABLED_SUCCESS;
    analytics.track(event, combineParams(params));
  };

  const featureToggledFailed = (isEnabled: boolean, params?: object): void => {
    const event = isEnabled ? TrackedEvents.FEATURE_ENABLED_FAILED : TrackedEvents.FEATURE_DISABLED_FAILED;
    analytics.track(event, combineParams(params));
  };

  const editUserQEPermissionsSuccess = (params: object & EditUserQEPermissionsEventParams) => {
    analytics.track(TrackedEvents.EDIT_USER_QE_PERMISSIONS_SUCCESS, combineParams(params));
  };

  const editUserQEPermissionsFailed = (params: object & EditUserQEPermissionsEventParams) => {
    analytics.track(TrackedEvents.EDIT_USER_QE_PERMISSIONS_FAILED, combineParams(params));
  };

  return {
    track,
    inviteUserSuccess,
    inviteUserFailed,
    removeUserSuccess,
    removeUserFailed,
    cancelUserSuccess,
    cancelUserFailed,
    featureToggledSuccess,
    featureToggledFailed,
    editUserQEPermissionsSuccess,
    editUserQEPermissionsFailed,
  } as const;
}

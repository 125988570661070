import { QText } from '@qualio/ui-components';
import React from 'react';
import { ConfirmationModal } from '../../../containers/confirmation-modal';
import { ModalComponentProps } from '../../../types';
import * as DisplayStrings from './__displayStrings__/DeleteUserConfirmation';
import { NoBtnLabel } from '../../../__displayStrings__/buttonsLabels';

type DeleteUserConfirmationProps = ModalComponentProps & {
  removeUser: () => void;
  isDeleting: boolean;
  name: string;
};

export const DeleteUserConfirmation = ({ closeModal, removeUser, isDeleting, name }: DeleteUserConfirmationProps) => {
  return (
    <ConfirmationModal
      data-cy="heading"
      closeModal={closeModal}
      title={DisplayStrings.DeleteUserTitle}
      size="lg"
      cancelLabel={NoBtnLabel}
      buttonProps={{
        type: 'button',
        label: DisplayStrings.DeleteUserButtonLabel,
        isDestructive: true,
        onClick: removeUser,
        isLoading: isDeleting,
      }}
    >
      <QText>{DisplayStrings.DeleteUserMessage({ name })}</QText>
    </ConfirmationModal>
  );
};

import { MedtechRole } from '../types/medtechRole.types';
import { readOnly, normal, quality } from '../__stringConstants__/qualioConstants';
import { KnownFlags } from './flagProvider';

// Improvements Required: QUM-500, QUM-501
export const FeatureEnum: { [featureKey: string]: string } = {
  '1': 'A “Quality Event” is an event that requires a pre-defined action or response and tracking in accordance with regulatory requirements. Quality users can create an event, assign ownership, and sign-off on associated steps/actions.',
  '2': 'Migrations manager allows you to easily upload documents into Qualio as you onboard.',

  // Quality Events permission Enums
  can_manage_quality_event_workflow: 'Manage workflow',
  can_manage_quality_event_attributes: 'Manage attributes',
  can_work_on_issue: 'Work on issue',
  can_create_issue: 'Create issue',

  // Migrations manager permission Enums
  can_create_migration: 'Create',
  can_view_migration: 'View',
  can_prepare_migration: 'Prepare',
  can_approve_migration: 'Approve',
  can_upload_migration_files: 'Upload Files',
};

export const FeatureToggleLabels: Record<string, string> = {
  1: 'Enable quality events',
  2: 'Enable migrations',
} as const;

export const FeatureLabels: Record<string, string> = {
  1: 'Quality events',
  2: 'Migrations',
} as const;

export const NavList: {
  [k: string]: { href: string; title: string; component: string; featureFlag?: KnownFlags; id: string };
} = {
  USERS: {
    href: 'users',
    title: 'Users',
    component: 'users',
    id: 'users',
  },
  GROUPS: {
    href: 'groups',
    title: 'Groups',
    component: 'groups',
    id: 'groups',
  },
  PERMISSIONS: {
    href: 'permissions',
    title: 'Permissions',
    component: 'permissions',
    id: 'permissions',
  },
  BETAS: {
    href: 'betas',
    title: 'Beta features',
    component: 'betaFeatures',
    featureFlag: 'qualioLabs',
    id: 'betas',
  },
};

export const UserGroupSortOrder = {
  [readOnly]: 1,
  [normal]: 2,
  [quality]: 3,
};

export const UsergroupsList: { [role: string]: { value: MedtechRole; label: string; sort: number } } = {
  QUALITY: {
    value: 'quality',
    label: 'Quality',
    sort: 3,
  },
  NORMAL: {
    value: 'normal',
    label: 'Normal',
    sort: 2,
  },
  BASIC: {
    value: 'read_only',
    label: 'Basic',
    sort: 1,
  },
};

export const UserActionItems = {
  EDIT_USER: {
    id: 'editUser',
    label: 'Edit user',
  },
  CANCEL_INVITE: {
    id: 'cancelInvite',
    label: 'Cancel invitation',
  },
  REMOVE_USER: {
    id: 'removeUser',
    label: 'Remove user',
  },
  REMOVE_DECLINED_USER: {
    id: 'removeDeclinedUser',
    label: 'Cancel invitation',
  },
  RESEND_INVITE: {
    id: 'resendInvite',
    label: 'Resend invitation',
  },
};

export const NO_DATA_MSG = 'There is no data to display.';

export const AssociationStatus = {
  FOR_REVIEW: {
    id: 'for_review',
    label: 'For review',
  },
  EFFECTIVE: {
    id: 'effective',
    label: 'Effective',
  },
  FOR_APPROVAL: {
    id: 'for_approval',
    label: 'For approval',
  },
  DRAFT: {
    id: 'draft',
    label: 'Draft',
  },
  APPROVAL_DECLINED: {
    id: 'approval_declined',
    label: 'Approval declined',
  },
  APPROVED: {
    id: 'approved',
    label: 'Approved',
  },
};

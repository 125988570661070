import { z } from 'zod';
import {
  CanViewQE,
  CanCreateIssue,
  CanWorkOnIssue,
  CanManageQualityEventWorkflow,
  CanAccessSuppliers,
  CanAccessBilling,
  CanAccessAPICapabilities,
  CanAccessDesignControls,
} from '../utils/validation/medtechPermissions';
import { ManageablePermissionsEnumSchema } from '../utils/validation/permissions';

export const ViewText = 'View events';
export const CreateText = 'Create an event';
export const EditText = 'Work on an existing event';
export const ManageText = 'Create and manage event templates';
export const AccessSuppliersText = 'Access Suppliers';
export const BillingAccess = 'Billing access';

type ManageablePermissions = z.infer<typeof ManageablePermissionsEnumSchema>;
export const PermissionsLabels: { [key in ManageablePermissions]: string } = {
  [CanAccessBilling]: BillingAccess,
  [CanViewQE]: ViewText,
  [CanCreateIssue]: CreateText,
  [CanWorkOnIssue]: EditText,
  [CanManageQualityEventWorkflow]: ManageText,
  [CanAccessSuppliers]: AccessSuppliersText,
  [CanAccessAPICapabilities]: 'Access API Cababilities',
  [CanAccessDesignControls]: 'Access Design Controls',
};

import React from 'react';
import { Routes } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useFlagProvider } from './utils/flagProvider';
import { AdminSettings } from './views';
import RemoveUserAssociations from './views/admin/RemoveUserAssociation';
import EditUser from './views/EditUser';

const App: React.FC = () => {
  const showEditUser =
    useFlagProvider().isFlagEnabled('umQeSelfService') || useFlagProvider().isFlagEnabled('umSuppliersSelfService');

  return (
    <Router basename="/user-management">
      <Routes>
        <Route path="/admin/*" element={<AdminSettings />} />
        {showEditUser && <Route path="/admin/users/:id" element={<EditUser />} />}
        <Route path="/admin/users/:id/remove" element={<RemoveUserAssociations />} />
        <Route path="/" element={<div>Page Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default App;

import * as React from 'react';

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<any, any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <h1>Something has gone wrong!</h1>;
    }

    return children;
  }
}

import { ReassignOwnerSummaryItemType } from '../../types';
import { BasicSummaryCategories, ReassignOwnerSummaryStrings, SummaryStrings } from '../types';

export const AlertText = 'Review information below';

export const OwnerStrings: ReassignOwnerSummaryStrings = {
  getTitle: (type: ReassignOwnerSummaryItemType) => {
    return type === ReassignOwnerSummaryItemType.document ? 'Documents:' : 'Change Requests:';
  },
  reassignStringBuilder: (itemCount: number, name: string, type: ReassignOwnerSummaryItemType) => {
    return ['Ownership of', `${itemCount} ${type}`, `will be transferred to ${name}`];
  },
} as const;

export const EventTemplateDefaultOwnerStrings = {
  title: 'Event Templates:',
  reassignStringBuilder: (templateCount: number, name: string) => {
    return templateCount === 1
      ? ['Default ownership of', `${templateCount} event template`, `will be transferred to ${name}`]
      : ['Default ownership of', `${templateCount} event templates`, `will be transferred to ${name}`];
  },
} as const;

const baseStringBuilder = (type: string, docCount: number, name: string): [string, string] => {
  return docCount === 1
    ? [`${docCount} ${type} action`, `will be transferred to ${name}`]
    : [`${docCount} ${type} actions`, `will be transferred to ${name}`];
};

export const StandardStrings: Record<BasicSummaryCategories, SummaryStrings> = {
  approver: {
    title: 'Approval actions:',
    reassignStringBuilder: baseStringBuilder.bind(null, 'approval'),
  },
  reviewer: {
    title: 'Review actions:',
    reassignStringBuilder: baseStringBuilder.bind(null, 'review'),
  },
  issues: {
    title: 'Issue actions:',
    reassignStringBuilder: baseStringBuilder.bind(null, 'issue'),
  },
  tasks: {
    title: 'Task actions:',
    reassignStringBuilder: baseStringBuilder.bind(null, 'task'),
  },
  changeRequestOwner: {
    title: 'Change Requests:',
    reassignStringBuilder: baseStringBuilder.bind(null, 'changeRequestOwner'),
  },
};

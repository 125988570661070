import React, { ReactElement } from 'react';
import { deprecated } from '@qualio/ui-components';

type AccessTableProps = {
  columns: deprecated.QDataColumn[];
  rows: {
    name: string;
    access: string;
    sort: number;
  }[];
};

export const AccessTable = (props: AccessTableProps): null | ReactElement => {
  const { columns, rows } = props;
  if (rows.length === 0) {
    return null;
  }

  return <deprecated.QDataTable columns={columns} data={rows} hasPagination={false} />;
};

import { QLink } from '@qualio/ui-components';
import React, { ReactElement } from 'react';
import { environment } from '../../../../environments/environment';

type UserCountMessageProps = {
  activeUsersCount: number;
  activeUsersLimit: number;
  totalUsersCount: number;
  totalUsersLimit: number;
};

const isMTFE = !!(window as any).angular;

export const UserCountMessage = ({
  activeUsersCount,
  activeUsersLimit,
  totalUsersCount,
  totalUsersLimit,
}: UserCountMessageProps): ReactElement => (
  <>
    You are using <strong>{activeUsersCount}</strong> of <strong>{activeUsersLimit}</strong> active users and{' '}
    <strong>{totalUsersCount}</strong> of <strong>{totalUsersLimit}</strong> total users available on your current plan.
    Need more?{' '}
    <QLink
      isCrossMFE
      data-cy="upgrade-subscription"
      href={isMTFE ? `${environment.mtfeEndpoint}/manage/billing` : '/manage/billing'}
    >
      Upgrade your subscription
    </QLink>
  </>
);

export const SetRole = 'Set role';
export const AddToGroup = (count: number) => {
  if (count < 1) return 'Add to group';
  const pluralized = count === 1 ? 'user' : 'users';
  return `Add ${count} ${pluralized} to group`;
};
export const RemoveFromGroup = (count: number) => {
  if (count < 1) return 'Remove from group';
  const pluralized = count === 1 ? 'user' : 'users';
  return `Remove ${count} ${pluralized} from group`;
};
export const InviteUser = 'Invite user';
export const ResendInvitationSuccessText = 'Invitation has been sent again!';
export const ResendInvitationFailedText = 'Something went wrong! Unable to resend invitation.';
export const CancelInvitationSuccessText = 'Successfully canceled user invitation.';
export const CancelInvitationFailedText = 'Something went wrong! Unable to cancel user invitation.';
export const RemoveUserSuccessText = 'Successfully removed user.';
export const RemoveUserFailedText = 'Something went wrong! Unable to remove user.';
export const FilterByGroupLabel = 'Filter by group';

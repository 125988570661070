export const mockedGroupsResponse = {
  data: [
    {
      id: 1,
      name: 'interns',
      include_in_reports: false,
    },
    {
      id: 2,
      name: 'Management',
      include_in_reports: true,
    },
    {
      id: 3,
      name: 'Super Secret Agent 99',
      include_in_reports: false,
    },
  ],
};

import React, { useEffect, useState } from 'react';
import {
  QButton,
  deprecated,
  QMenuButton,
  QMenuItemType,
  QSpinner,
  QText,
  useCurrentUser as useQCurrentUser,
} from '@qualio/ui-components';
import { Group, GroupRowData } from '../../types';
import { NO_DATA_MSG } from '../../utils/umfe.enums';
import { CreateGroupModal } from '../modals';
import { EditGroupModal } from '../modals/EditGroupModal';
import { DeleteGroupModal } from '../modals/DeleteGroupModal';
import { getGroups } from '../../api/medtech-api';

type GroupQMenuItemType = QMenuItemType & { group: Group };

export const Groups: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const [isEditGroupModalOpen, setEditGroupModalOpen] = useState(false);
  const [isDeleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
  const [clickedGroup, setClickedGroup] = useState<Group>();
  const [groups, setGroups] = useState<GroupRowData[]>();
  const { companyId: currentCompanyId } = useQCurrentUser();

  const columns = [
    {
      Header: 'Group name',
      accessor: 'name',
    },
    {
      Header: 'Include in training reports',
      accessor: 'includeInReports',
    },
    {
      Header: '',
      accessor: 'actions',
      disableSortBy: true,
      width: '5%',
    },
  ];

  const handleAction = (action: GroupQMenuItemType) => {
    setClickedGroup(action.group);
    const { id: actionItemId } = action;
    if (actionItemId === 'editGroup') {
      setEditGroupModalOpen(true);
    }
    if (actionItemId === 'deleteGroup') {
      setDeleteGroupModalOpen(true);
    }
  };

  const formatGroups = (groupsData: Group[]) => {
    return groupsData.map((group) => {
      return {
        id: group.id,
        name: group.name,
        includeInReports: group.include_in_reports ? 'Yes' : 'No',
        actions: (
          <QMenuButton
            buttonLabel=""
            iconName="MoreVertical"
            itemSize="sm"
            items={[
              {
                id: 'editGroup',
                label: 'Edit group',
                group,
              } as GroupQMenuItemType,
              {
                id: 'deleteGroup',
                label: 'Delete group',
                group,
              } as GroupQMenuItemType,
            ]}
            onItemClick={(item) => handleAction(item as GroupQMenuItemType)}
            variant="icon"
          />
        ),
      };
    });
  };

  const fetchGroups = async (companyId: number) => {
    setLoading(true);

    const { data } = await getGroups(companyId);

    setGroups(formatGroups(data));
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups(currentCompanyId);
  }, [currentCompanyId]);

  if (isLoading) {
    return <QSpinner size="sm" />;
  }

  return (
    <>
      <div className="text-right">
        <QButton data-cy="create-new-group-button" onClick={() => setCreateGroupModalOpen(true)}>
          Create new group
        </QButton>
      </div>
      <deprecated.QDataTable columns={columns} data={groups!} hasPagination={false} />
      {!groups?.length && <QText>{NO_DATA_MSG}</QText>}
      {isCreateGroupModalOpen && (
        <CreateGroupModal
          closeModal={() => setCreateGroupModalOpen(false)}
          currentGroups={groups!}
          setRefetch={() => fetchGroups(currentCompanyId)}
        />
      )}
      {isEditGroupModalOpen && (
        <EditGroupModal
          closeModal={() => setEditGroupModalOpen(false)}
          group={clickedGroup!}
          currentGroups={groups!}
          setRefetch={() => fetchGroups(currentCompanyId)}
        />
      )}
      {isDeleteGroupModalOpen && (
        <DeleteGroupModal
          closeModal={() => setDeleteGroupModalOpen(false)}
          group={clickedGroup!}
          setRefetch={() => fetchGroups(currentCompanyId)}
        />
      )}
    </>
  );
};

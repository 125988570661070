export const TrackedEvents = {
  INVITE_USER_SUCCESS: 'UM | Invite user success',
  INVITE_USER_FAILED: 'UM | Invite user failed',
  INVITE_ADMIN_SUCCESS: 'UM | Invite admin user success',
  INVITE_ADMIN_FAILED: 'UM | Invite admin user failed',
  REMOVE_USER_SUCCESS: 'UM | Remove user success',
  REMOVE_USER_FAILED: 'UM | Remove user failed',
  CANCEL_INVITATION_SUCCESS: 'UM | Cancel invitation success',
  CANCEL_INVITATION_FAILED: 'UM | Cancel invitation failed',
  FEATURE_ENABLED_SUCCESS: 'UM | Feature enabled success',
  FEATURE_ENABLED_FAILED: 'UM | Feature enabled failed',
  FEATURE_DISABLED_SUCCESS: 'UM | Feature disabled success',
  FEATURE_DISABLED_FAILED: 'UM | Feature disabled failed',
  EDIT_USER_QE_PERMISSIONS_SUCCESS: 'UM | Update user event permissions success',
  EDIT_USER_QE_PERMISSIONS_FAILED: 'UM | Update user event permissions failed',
} as const;

import React, { ReactElement } from 'react';
import createCommaSeparatedListMessage from '../../../utils/createCommaSeparatedList';

export const RemoveUserGroupModalHeader = 'Remove selected users from group';
export const CancelBtnLabel = 'Cancel';
export const RemoveBtnLabel = 'Remove';
export const UserLosingDocumentVisibilityMessageSuffix = 'will no longer be able to view certain documents!';
export const ConfirmButtonLabel = 'Confirm';
export const ConfirmationModalTitle = 'This action affects content visibility';
export const ErrorRemovingUsersFromGroupsMessage = 'Unable to remove user(s) from selected group';
export const SuccessMessage = (usersAffectedCount: number) =>
  `Successfully removed ${usersAffectedCount} user(s) from chosen group!`;

/**
 * @description Returns a message that displays a list of user names that will lose access to view certain documents
 */
export const UsersLoseDocumentVisibilityMessage = (users: string[]): ReactElement => {
  return (
    <span>
      {createCommaSeparatedListMessage(users, {
        bold: true,
        messageSuffix: UserLosingDocumentVisibilityMessageSuffix,
      })}
    </span>
  );
};

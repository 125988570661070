import React, { useEffect, useState } from 'react';
import {
  QAlert,
  QEmptyState,
  QLink,
  QSpinner,
  QStack,
  QSubtitle,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { Feature, FeaturesResponse } from '../../../types/features.types';
import { getCompanyFeatures } from '../../../api/medtech-api';
import { BetaFeaturesControl } from './BetaFeaturesControl/BetaFeaturesControl';
import * as flagProvider from '../../../utils/flagProvider';

export const BetaFeatures = () => {
  const currentUser = useCurrentUser();
  const { isFlagEnabled } = flagProvider.useFlagProvider();
  const { showToast } = useToastProvider();
  const [isLoading, setLoading] = useState(true);
  const [features, setFeatures] = useState<Feature[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: FeaturesResponse = await getCompanyFeatures(currentUser.companyId);
        if (response.data && response.data.features) {
          setFeatures(response.data.features);
        } else {
          throw new Error('Invalid API response format');
        }
      } catch (error) {
        showToast({
          id: 'fetch-features',
          replace: true,
          description: 'Failed to fetch features',
          status: 'error',
        });
      }
      setLoading(false);
    };
    fetchData();
  }, [currentUser.companyId, showToast]);

  if (isLoading) {
    return <QSpinner size="sm" />;
  }
  const subHeading =
    "Explore and test the latest features we're crafting by enabling them in your instance. Your feedback helps us refine and perfect these innovations, so let us know what you think. When enabling a beta feature, it may take a few minutes for the change to take effect. If you don't see the changes, try refreshing your browser. Learn more about these features on our";

  const helpCenterLink = (
    <QLink href="https://docs.qualio.com/en/articles/8681740-current-open-betas" isExternal>
      Help Center.
    </QLink>
  );

  if (!features || features.length === 0) {
    return (
      <QEmptyState
        title="No active beta features available"
        subtitle="Please check back again in the future. We are always working on improvements to the Qualio experience"
      />
    );
  }

  return (
    <QStack spacing="10">
      <QSubtitle>
        {subHeading} {helpCenterLink}
        {isFlagEnabled('termsOfService') && (
          <QStack style={{ marginTop: '20px' }}>
            <QAlert
              status="info"
              data-cy="tos-alert-beta"
              isInline
              description={
                <QText data-cy="tos-text-beta">
                  By clicking a toggle to enable any of the following beta features, you agree to be bound by
                  Qualio&apos;s{' '}
                  <QLink href="https://www.qualio.com/docs/terms" isExternal>
                    Terms of Service,
                  </QLink>{' '}
                  whereby the beta features you enable will be part of the &quot;Beta features&quot; for purposes of the{' '}
                  <QLink href="https://www.qualio.com/docs/terms" isExternal>
                    {' '}
                    Terms of Service.
                  </QLink>{' '}
                </QText>
              }
            />
          </QStack>
        )}
      </QSubtitle>
      {features.map((feature) => (
        <BetaFeaturesControl
          key={feature.key}
          featureKey={feature.key}
          description={feature.description}
          name={feature.name}
          active={feature.active}
        />
      ))}
    </QStack>
  );
};

import { QMultiSelect, QMultiSelectProps, QSelectPlaceholder, QText } from '@qualio/ui-components';
import React from 'react';

export type MultiSelectProps = QMultiSelectProps & {
  fixedWidth?: string;
  placeholder: string;
};

export const MultiSelect: React.FC<MultiSelectProps> = ({ fixedWidth, placeholder, options, onChange, value }) => {
  return (
    <span style={{ width: fixedWidth }}>
      <QMultiSelect options={options} onChange={onChange} value={value}>
        <QSelectPlaceholder>
          <QText>{placeholder}</QText>
        </QSelectPlaceholder>
      </QMultiSelect>
    </span>
  );
};

import React from 'react';
import { QHeading, QStack } from '@qualio/ui-components';
import UserInfo from '../../../components/FormComponents/UserInfo';
import { GeneralHeader } from './__displayStrings__';
import { useFlagProvider } from '../../../utils/flagProvider';

type GeneralSectionProps = {
  inviteIsPending: boolean;
};

const GeneralSection = ({ inviteIsPending }: GeneralSectionProps) => {
  const isSuppliersSSEnabled = useFlagProvider().isFlagEnabled('umSuppliersSelfService');
  return (
    <QStack direction={['column']}>
      <QHeading fontWeight="bold" size={isSuppliersSSEnabled ? 'md' : 'sm'}>
        {GeneralHeader}
      </QHeading>
      <UserInfo inviteIsPending={inviteIsPending} />
    </QStack>
  );
};

export default GeneralSection;

import { QToastProps } from '@qualio/ui-components';

export const createSuccessToast = (msg: string, title: string = 'Success') => {
  return {
    title,
    description: msg,
    status: 'success',
    duration: 5000,
  } as QToastProps;
};

export const createErrorToast = (msg: string, title: string = 'Error') => {
  return {
    title,
    description: msg,
    status: 'error',
    duration: 5000,
  } as QToastProps;
};

export const createWarningToast = (msg: string, title: string = 'Warning') => {
  return {
    title,
    description: msg,
    status: 'warning',
    duration: 5000,
  } as QToastProps;
};

export const createInfoToast = (msg: string, title: string = 'Info') => {
  return {
    title,
    description: msg,
    status: 'info',
    duration: 5000,
  } as QToastProps;
};

import { useToastProvider } from '@qualio/ui-components';
import React, { SyntheticEvent, useState } from 'react';
import { AxiosError } from 'axios';
import { ConfirmationModal } from '../../containers/confirmation-modal';
import { Group, GroupRowData, ModalComponentProps } from '../../types';
import { createSuccessToast, createErrorToast } from '../../utils/toast.utils';
import { GroupForm } from '../forms/GroupForm';
import { patchGroup } from '../../api/medtech-api';

type EditGroupModalProps = ModalComponentProps & {
  group: Group;
  currentGroups: GroupRowData[];
  setRefetch: () => void;
};

export const EditGroupModal: React.FC<EditGroupModalProps> = ({ closeModal, group, currentGroups, setRefetch }) => {
  const { showToast } = useToastProvider();
  const currentGroupNames = currentGroups
    .map((g) => g.name.toLowerCase())
    .filter((name) => name !== group.name.toLowerCase());
  const [groupName, setGroupName] = useState(group.name);
  const [isIncludedInReports, setIncludeInReports] = useState(group.include_in_reports ?? false);
  const [isUpdatingGroup, setUpdatingGroup] = useState(false);
  const [isFormValid, setFormValid] = useState(false);

  const handleUpdateGroup = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Return early if user pressed 'enter' when form is invalid
    if (!isFormValid) {
      return;
    }
    setUpdatingGroup(true);

    const payload = {
      name: groupName.trim(),
      include_in_reports: isIncludedInReports,
    };

    try {
      await patchGroup(group.id, payload);
      setUpdatingGroup(false);
      showToast(createSuccessToast('Successfully updated group!'));
      setRefetch();
      closeModal();
    } catch (error) {
      const axiosError = error as AxiosError;
      setUpdatingGroup(false);
      showToast(createErrorToast(axiosError.message || 'Something went wrong! Unable to update group.'));
    }
  };
  return (
    <ConfirmationModal
      closeModal={closeModal}
      title="Edit group"
      buttonProps={{
        isDisabled: !isFormValid,
        isLoading: isUpdatingGroup,
        onClick: handleUpdateGroup,
        label: 'Confirm',
      }}
    >
      <GroupForm
        handleSubmit={handleUpdateGroup}
        currentGroupNames={currentGroupNames}
        groupName={groupName}
        setGroupName={setGroupName}
        isIncludedInReports={isIncludedInReports}
        setIncludeInReports={setIncludeInReports}
        setFormValid={setFormValid}
      />
    </ConfirmationModal>
  );
};

export const mockedDocument = {
  id: 42069,
  title: 'Elon buys twitter',
  status_id: 'approved',
  code: 'AP-420',
  version: '0.0.01',
  tag_ids: ['elon', 'Buys', 'twitter', 'tesla'],
  accessible_by_group_ids: [1],
  context: 'author_group',
  tags: [
    { id: 0, name: 'elon' },
    { id: 1, name: 'Buys' },
    { id: 2, name: 'twitter' },
    { id: 3, name: 'tesla' },
  ],
};

export const mockedChangeRequest = {
  id: 65,
  created_at: 1703181697,
  closed_at: null,
  is_deleted: false,
  owner: {
    id: 10365,
    full_name: 'Bruno Marques',
    usercompany_id: 39851,
  },
  status: 'in_progress',
  title: 'mock test cr',
  company_id: 605,
  last_modified_at: 1703182157,
  code_prefix: 'DCR-',
  code_number: 49,
  code: 'DCR-49',
};

export const mockedPeriodicReview = {
  id: 1010,
  title: 'Fake Periodic Review',
  status_id: 'retired',
  code: 'AP-420',
  version: '0.0.01',
  tag_ids: ['periodic', 'review'],
  accessible_by_group_ids: [1],
  context: 'author_group',
  tags: [],
};

export const mockedIssues = {
  id: 14,
  title: 'Blue origin rocket can barely make it to space',
  code: 'BLUE_O - 1000',
  url: 'copy/1/issues/14',
};

export const mockedEventTemplate = {
  id: 33428,
  prefix: 'IBT',
  name: 'Ian Be Testing',
  active: true,
  company_id: 605,
  sequence_id: 1,
  lineage_id: 9001,
  major_version: 1,
  minor_version: 0,
  issues_count: 0,
  default_owner_id: 42,
  default_owner: 'Ian B. Testing',
};

export const mockedUserAssociations = {
  approver: {
    documents: [mockedDocument],
    periodic_reviews: [mockedPeriodicReview],
  },
  issues: {
    issues: [mockedIssues],
  },
  owner: {
    documents: [mockedDocument],
    change_requests: [mockedChangeRequest],
  },
  reviewer: {
    documents: [mockedDocument],
  },
  tasks: {
    tasks: [mockedIssues],
  },
  default_owner: {
    event_templates: [mockedEventTemplate],
  },
};

export const mockUserNoAssociations = {
  approver: {
    documents: [],
    periodic_reviews: [],
  },
  issues: {
    issues: [],
  },
  owner: {
    documents: [],
    change_requests: [],
  },
  reviewer: {
    documents: [],
  },
  tasks: {
    tasks: [],
  },
  default_owner: {
    event_templates: [],
  },
};

export const getPayload = (data: {
  approver?: any;
  issues?: any;
  owner?: any;
  changeRequestOwner?: any;
  reviewer?: any;
  tasks?: any;
  default_owner?: any;
}) => {
  const { approver, issues, owner, changeRequestOwner, reviewer, tasks, default_owner: defaultOwner } = data;
  return {
    data: {
      approver: {
        documents: approver || [],
        periodic_reviews: [],
      },
      issues: {
        issues: issues || [],
      },
      owner: {
        documents: owner || [],
        change_requests: changeRequestOwner || [],
      },
      reviewer: {
        documents: reviewer || [],
      },
      tasks: {
        tasks: tasks || [],
      },
      default_owner: {
        event_templates: defaultOwner || [],
      },
    },
  };
};

export const mockedDocuments = [mockedDocument];

export const mockedPotentialOwners = new Map([
  [
    0,
    {
      userId: 0,
      documentId: 0,
      name: 'Elon Musk',
    },
  ],
  [
    1,
    {
      userId: 1,
      documentId: 1,
      name: 'Jeff Bezos',
    },
  ],
  [
    2,
    {
      userId: 1,
      documentId: 2,
      name: 'Jeff Bezos',
    },
  ],
  [
    3,
    {
      userId: 1,
      documentId: 3,
      name: 'Jeff Bezos',
    },
  ],
]);

const genDockOverRide = () => {
  return new Map([
    [
      3,
      {
        userId: 2,
        documentId: 3,
        name: 'test1',
      },
    ],
  ]);
};

export const mockNewDocOwnerData = {
  documentOverride: genDockOverRide(),
  selectedItems: 3,
};
export const mockNewApprovalOwner = {
  documentOverride: genDockOverRide(),
  selectedItems: 3,
};
export const mockNewReviewerOwner = {
  documentOverride: genDockOverRide(),
  selectedItems: 3,
};
export const mockNewIssueOwner = {
  documentOverride: genDockOverRide(),
  selectedItems: 3,
};
export const mockNewTaskOwner = {
  documentOverride: genDockOverRide(),
  selectedItems: 3,
};

export const mockDeletePayload = {
  id: 1,
  isQm: false,
  newOwnerOverride: [
    {
      userId: 2,
      documentId: 3,
    },
  ],
  newApproverOverride: [
    {
      userId: 2,
      documentId: 3,
    },
  ],
  newReviewerOverride: [
    {
      userId: 2,
      documentId: 3,
    },
  ],
  newIssueOwnerOverride: [
    {
      userId: 2,
      documentId: 3,
    },
  ],
  newTaskOwnerOverride: [
    {
      userId: 2,
      documentId: 3,
    },
  ],
};

import { QAlert, deprecated, QSwitch, QText, QFlex } from '@qualio/ui-components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '../../containers/confirmation-modal';
import { AlertMessageText, ConfirmModalButtonText, ConfirmModalText, ConfirmModalTitle } from './__displayStrings__';
import '../../styles/CompanyFeature.css';
import { FeatureStatus, UserGroupPermissions } from '../../types';
import { getFeatureAccessName, isFeature } from '../../utils/translator';
import { FeatureToggleLabels, UsergroupsList } from '../../utils/umfe.enums';
import { AccessTable } from './AccessTable';

const AlertContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0px;
`;

type FeatureProps = {
  id: string;
  featureId: string;
  name: string;
  info: string;
  setFeatureStatus: (active: FeatureStatus) => void;
  isActive: boolean;
  userGroupPermissions: UserGroupPermissions[];
  isStarterCompany: boolean;
};

export const FeatureComponent: React.FC<FeatureProps> = ({
  id,
  featureId,
  name,
  info,
  setFeatureStatus,
  isActive,
  userGroupPermissions,
  isStarterCompany,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const tableColumns: deprecated.QDataColumn[] = [
    {
      Header: 'Role',
      accessor: 'name',
      width: '20%',
    },
    {
      Header: 'Access',
      accessor: 'access',
      width: '80%',
    },
  ];
  const tableData = userGroupPermissions
    .map((userGroup) => {
      const { userGroup: role } = userGroup;
      const accessString =
        userGroup.userGroupPermissions.length === 0
          ? 'None'
          : userGroup.userGroupPermissions
              .filter((usergroupPermission) => isFeature(usergroupPermission.permission.permission))
              .map((usergroupPermission) => {
                return getFeatureAccessName(usergroupPermission.permission.permission);
              })
              .join(', ');
      const titlecasedRole = role.charAt(0).toUpperCase() + role.substring(1);
      const { sort, label: roleLabel } = Object.values(UsergroupsList).find(
        (usergroup) => usergroup.value === role,
      ) || {
        label: titlecasedRole,
        sort: 0,
      };
      return {
        name: roleLabel,
        access: accessString,
        sort,
      };
    })
    .sort((a, b) => a.sort - b.sort);
  const handleConfirmationModalChange = () => {
    setConfirmationModalOpen(true);
  };
  const handleChange = () => {
    setFeatureStatus({
      id,
      featureId,
      buttonStatus: !isActive,
    });
    setConfirmationModalOpen(false);
  };

  return (
    <QFlex direction="column">
      <div className="feature-title">
        <QText fontSize="md" as="h6">
          {name}
        </QText>
      </div>
      <div className="feature-info">
        <QText fontSize="sm" fontWeight="normal">
          {info}
        </QText>
        <QSwitch
          isChecked={isActive}
          onChange={!isActive ? handleChange : handleConfirmationModalChange}
          isDisabled={isStarterCompany}
          rightLabel={FeatureToggleLabels[featureId] || 'Enable feature'}
        />
        {isStarterCompany ? (
          <AlertContainer>
            <QAlert description={AlertMessageText} status="info" />
          </AlertContainer>
        ) : (
          <AccessTable columns={tableColumns} rows={tableData} />
        )}
        {confirmationModalOpen && (
          <ConfirmationModal
            closeModal={() => setConfirmationModalOpen(false)}
            title={ConfirmModalTitle(name)}
            buttonProps={{
              type: 'button',
              label: ConfirmModalButtonText(name),
              isDestructive: true,
              onClick: handleChange,
            }}
          >
            {ConfirmModalText}
          </ConfirmationModal>
        )}
      </div>
    </QFlex>
  );
};

import React, { ReactElement, useEffect, useState } from 'react';
import { QDivider, QStack, useCurrentUser as useQCurrentUser } from '@qualio/ui-components';
import { useParams } from 'react-router';
import { getCompanyByIdV2, getUserById } from '../../api/um-api';
import Loading from '../../components/Loading';
import EditUserHeader from './EditUserHeader';
import EditUserContent from './EditUserContent';
import { UserV2Type } from '../../utils/validation/user';
import { CompanyInfo } from '../../types';
import { MedtechPermissionsByRolesMap } from '../../utils/validation/permissions';
import { getMedtechPermissions } from '../../api/medtech-api';

const EditUser = (): ReactElement => {
  const { companyId: currentCompanyId } = useQCurrentUser();
  const params = useParams<{ id: string }>();

  const userIdFromParams = Number(params.id);
  const [user, setUser] = useState<UserV2Type>();
  const [company, setCompany] = useState<CompanyInfo>();
  const [permissionsByRole, setPermissionsByRoleMap] = useState<MedtechPermissionsByRolesMap>();
  const isLoading = !(user && company && permissionsByRole);

  const getUser = async (companyId: number, userId: number) => {
    const fetchedUser = await getUserById(companyId, userId);
    setUser(fetchedUser);
  };

  const getCompany = async (companyId: number) => {
    const fetchedCompany = await getCompanyByIdV2(companyId);
    setCompany(fetchedCompany);
  };

  const getDefaultPermissions = async (companyId: number) => {
    const permissions = await getMedtechPermissions(companyId);
    setPermissionsByRoleMap(permissions);
  };

  useEffect(() => {
    getUser(currentCompanyId, userIdFromParams);
    getCompany(currentCompanyId);
    getDefaultPermissions(currentCompanyId);
  }, [currentCompanyId, userIdFromParams]);

  return (
    <QStack direction={['column']}>
      <EditUserHeader />
      <QDivider />
      {isLoading ? (
        <Loading />
      ) : (
        <EditUserContent user={user} company={company} defaultPermissions={permissionsByRole} />
      )}
    </QStack>
  );
};

export default EditUser;

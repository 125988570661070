import { useCurrentUser as useQCurrentUser } from '@qualio/ui-components';
import React from 'react';
import { environment } from '../environments/environment';
import { useAuthExchangeHook } from '../hooks/auth-exchange.hook';

type AuthContainerProps = {
  children: any;
};

export const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  const { companyId } = useQCurrentUser();

  const { isLoading, isSuccess, isError } = useAuthExchangeHook(companyId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    window.location.replace(environment.loginUrl);
    return null;
  }

  if (isSuccess) {
    return children;
  }

  return null;
};

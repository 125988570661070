import { z } from 'zod';

export const UMErrorSchema = z
  .object({
    errorCode: z.string(),
    message: z.string().optional(),
    data: z
      .object({
        slug: z.string().optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();

export type UMErrorType = z.infer<typeof UMErrorSchema>;

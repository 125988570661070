import { QHeader, QTab, QTitle, QBodyLayout } from '@qualio/ui-components';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RenderAdminContent } from '../components';
import { UnauthorizedView } from '../containers/unauthorized';
import '../styles/AdminSettings.css';
import { NavList } from '../utils/umfe.enums';
import { Groups, Users } from './admin';
import { HeaderText } from './__displayStrings__/AdminSettings';
import { useFlagProvider } from '../utils/flagProvider';
import { Permissions } from './admin/Permissions/Permissions';
import { BetaFeatures } from './admin/BetaFeatures/BetaFeatures';

export const getComponentByHref = (href: string) => {
  switch (href) {
    case NavList.USERS.href:
      return <Users />;
    case NavList.GROUPS.href:
      return <Groups />;
    case NavList.PERMISSIONS.href:
      return <Permissions />;
    case NavList.BETAS.href:
      return <BetaFeatures />;
    default:
      return <UnauthorizedView />;
  }
};

export const getCurrentPath = (pathname: string): string | undefined =>
  pathname
    .split('/')
    .filter((path: string) => path !== 'admin')
    .pop();

export const AdminSettings: React.FC = () => {
  const navigate = useNavigate();
  const { isFlagEnabled } = useFlagProvider();
  const navItems = useMemo(
    () => Object.values(NavList).filter((item) => !item.featureFlag || isFlagEnabled(item.featureFlag)),
    [isFlagEnabled],
  );
  const defaultNavItem = navItems[0];
  const { pathname } = useLocation();
  const currentPath: string | undefined = getCurrentPath(pathname);

  const currentIndex = useMemo(() => navItems.findIndex((item) => item.href === currentPath), [currentPath]);

  useEffect(() => {
    if (!currentPath && defaultNavItem) {
      navigate(defaultNavItem.href, { replace: true });
    }
  }, [currentPath]);

  const content = useMemo(
    () =>
      navItems.map(({ href, title }) => (
        <RenderAdminContent key={href} title={title} component={getComponentByHref(href)} />
      )),
    [navItems],
  );

  const tabs = useMemo(
    () =>
      navItems.map((navItem) => (
        <QTab data-cy={`${navItem.id}-tab-button`} key={navItem.href}>
          {navItem.title}
        </QTab>
      )),
    [navItems],
  );

  // If no nav items are enabled, then we show nothing!
  if (!defaultNavItem) {
    return <UnauthorizedView />;
  }

  return (
    <QBodyLayout.SplitPanelLeftWithTabs
      onChange={(index) => navigate(`${navItems[index].href}`)}
      index={currentIndex >= 0 ? currentIndex : 0}
      isLazy
    >
      <QHeader>
        <QTitle>{HeaderText}</QTitle>
      </QHeader>
      {tabs}

      {currentIndex >= 0 ? content : <UnauthorizedView />}
    </QBodyLayout.SplitPanelLeftWithTabs>
  );
};

import React, { useState } from 'react';
import { QStack, QSwitch, QText, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import DOMPurify from 'dompurify';
import { patchCompanyFeatures } from '../../../../api/medtech-api';

type BetaFeaturesControlProps = {
  featureKey: string;
  name: string;
  description: string;
  active: boolean;
};

const HtmlDescription: React.FC<{ description: string }> = ({ description }) => {
  const sanitizedHtml = DOMPurify.sanitize(description, {
    ADD_ATTR: ['target', 'rel'],
  });
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHtml, 'text/html');
  const links = doc.querySelectorAll('a');

  links.forEach((link) => {
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
  });

  return (
    <QText color="gray.500" fontSize="sm" maxWidth="720px" dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />
  );
};

export const BetaFeaturesControl = ({ featureKey, name, description, active }: BetaFeaturesControlProps) => {
  const currentUser = useCurrentUser();
  const [isChecked, setIsChecked] = useState(active);
  const { showToast } = useToastProvider();

  const handleToggleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    setIsChecked(isEnabled);
    try {
      await patchCompanyFeatures(currentUser.companyId, {
        flag_key: featureKey,
        flag_title: name,
        enable: isEnabled,
      });
      const messageVariant = isEnabled ? 'Enabled' : 'Disabled';
      showToast({
        id: 'patch-features',
        replace: true,
        description: `${messageVariant} ${name}`,
        status: 'success',
      });
    } catch (error) {
      showToast({
        id: 'patch-features',
        replace: true,
        description: 'Failed update features',
        status: 'error',
      });
    }
  };

  return (
    <QStack spacing="4">
      <QStack spacing="2">
        <QText fontWeight="semibold" fontSize="md">
          {name}
        </QText>
        <HtmlDescription description={description} />
        <QSwitch
          data-cy={`${featureKey}-switch`}
          isChecked={isChecked}
          onChange={handleToggleChange}
          rightLabel={`Enable ${name}`}
        />
      </QStack>
    </QStack>
  );
};

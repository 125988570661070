import React, { ReactElement } from 'react';

export const InviteUser = 'Invite user';
export const InvitationSentSuccessText = 'Invitation sent!';
export const InvitationSentFailedText = 'Something went wrong! Unable to send invitation.';
export const EmailAddressHelperText =
  'An invitation message will be sent to the provided email address. It will contain a confirmation link which allows the email address owner to join your organization.';
export const CancelBtnLabel = 'Cancel';
export const SendInvitationLabel = 'Send invitation';
export const ResendInvitationLabel = 'Resend invitation';
export const FormerUserMessage = (email: string): ReactElement => (
  <>
    <strong>{email}</strong> was previously an active member of this account. Reinviting them will restore their profile
    with previously completed activities.
  </>
);

import React, { ReactElement } from 'react';

export const CancelInvitationButtonLabel = 'Yes, cancel user';
export const CancelInvitationTitle = 'Cancel invitation';

/**
 * @description Message for canceling a user's invitation
 */
export const CancelInvitationMessage = (): ReactElement => {
  return (
    <>
      Are you sure you want to <strong>cancel</strong> this user&apos;s invitation?
    </>
  );
};

export const MinimumRequiredAdminsErrorMessage =
  'This operation would result in zero admins for the company. The minimum required admins for each company is 1.';

export const MinimumRequiredQualityUsersErrorMessage = 'Company requires at least one Quality user.';

export const DocumentsHiddenFromAuthorsErrorMessage =
  'This action would cause one or more documents to be hidden from their authors.';

export const AlreadyActiveUserErrorMessage = (email: string) =>
  `User registered with email ${email} is already a member of this company.`;

export const TotalUsersLimitReachedErrorMessage = 'Limit of total users reached.';
export const ActiveUsersLimitReachedErrorMessage = 'Limit of active users reached.';
export const InvalidEmailErrorMessage = 'Invalid email address.';

import { z } from 'zod';
import { isAxiosError } from '../utils/validation/axios';
import { UMErrorSchema } from '../utils/validation/um-error';

export const ErrorCodes = {
  MINIMUM_ADMINS_REQUIRED: 'MINIMUM_ADMINS_REQUIRED',
  MINIMUM_QM_USER_REQUIRED: 'MINIMUM_QM_USER_REQUIRED',
  DOCUMENTS_HIDDEN_FROM_AUTHORS: 'DOCUMENTS_HIDDEN_FROM_AUTHORS',
  INVALID_USER_UPDATE: 'INVALID_USER_UPDATE',
  ALREADY_ACTIVE_USER: 'ALREADY_ACTIVE_USER',
  ACTIVE_USERS_LIMIT_REACHED: 'ACTIVE_USERS_LIMIT_REACHED',
  TOTAL_USERS_LIMIT_REACHED: 'TOTAL_USERS_LIMIT_REACHED',
  INVALID_EMAIL: 'INVALID_EMAIL',
} as const;

type UMErrorMessageMap = Partial<Record<keyof typeof ErrorCodes, string>>;

/**
 * @description Attempts to return a UM error code from the caught error.
 * If not an AxiosError or no errorCode is found, then it returns null.
 */
export const getUMErrorCode = (err: unknown): string | null => {
  if (isAxiosError(err)) {
    const parsedError = UMErrorSchema.safeParse(err.response?.data);

    if (parsedError.success) {
      return parsedError.data.errorCode;
    }
  }

  return null;
};

export const getUMErrorResponseMessageOrDefault = (error: unknown, defaultMessage: string) => {
  const errorResponseMessageSchema = UMErrorSchema.extend({
    message: z.string(),
  });

  if (isAxiosError(error)) {
    const parsedError = errorResponseMessageSchema.safeParse(error.response?.data);

    if (parsedError.success) {
      return parsedError.data.message;
    }
  }

  return defaultMessage;
};

export const getErrorMessageOrDefault = (
  error: unknown,
  errorMessageMap: UMErrorMessageMap,
  defaultErrorMessage: string,
) => {
  const errorCode = getUMErrorCode(error);
  return errorMessageMap[errorCode as keyof typeof errorMessageMap] || defaultErrorMessage;
};

import { z } from 'zod';
import { MedtechRoles } from '../../types/medtechRole.types';
import { emailSchema } from './email';
import { GroupSchema } from './group';
import { MedtechPermissionsSchema } from './medtechPermissions';

export const UserV2Schema = z.object({
  id: z.number(),
  email: emailSchema,
  fullName: z.string(),
  staff: z.boolean().nullable(),
  createdTime: z.number(),
  inviteStatus: z.string(),
  active: z.boolean().nullable(),
  groups: z.array(GroupSchema),
  role: z.enum(MedtechRoles),
  tz: z.string().nullable(),
  medtechPermissions: MedtechPermissionsSchema,
  isAdmin: z.boolean(),
});

export type UserV2Type = z.infer<typeof UserV2Schema>;

// Parse potential user object
export const validateUserV2 = (user: UserV2Type) => UserV2Schema.parse(user);

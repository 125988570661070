import styled from 'styled-components';

export const BulkSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 83.46%;

  /* gray/50 */
  background: #f9f9fa;

  /* gray/200 */
  border: 0.0625rem solid #e3e3e5;
  box-sizing: border-box;
  border-radius: 0.25rem;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 1rem 0rem;
`;

export const BulkQSelect = styled.div`
  width: 30%;
  height: 5%;
  background: #ffffff;
`;

export const BulkSelectCloseButton = styled.div`
  padding-left: 23.625rem;
  color: #53545e;
`;

import React from 'react';
import { QSpacer, QStack } from '@qualio/ui-components';
import * as DisplayStrings from './__displayStrings__/index';
import { Header } from '../../containers/header';

const EditUserHeader = (): React.ReactElement => {
  return (
    <QStack direction={['row']}>
      <Header content={DisplayStrings.HeaderText} />
      <QSpacer />
    </QStack>
  );
};

export default EditUserHeader;

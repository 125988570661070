import { QSelectItem } from '@qualio/ui-components';
import { Group } from '../types';

/**
 * @description Formats MTBE groups to QSelectItems
 */
export const formatGroupsToSelectOptions = (groups: Group[]): QSelectItem[] => {
  return groups.map(({ id, name }) => {
    return {
      label: name.toUpperCase(),
      value: id.toString(),
    };
  });
};

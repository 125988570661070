import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import HttpStatus from 'http-status';
import { environment } from '../environments/environment';
import { EnvironmentEnum } from '../types';

export const axiosClient = axios.create({
  withCredentials: true,
});

if (environment.name === EnvironmentEnum.LOCAL) {
  const mock = new MockAdapter(axiosClient, { delayResponse: 10 });

  // Mock auth request to pass OK response for user's companyId
  mock.onPost(/dummy-auth-api-url.com\/company\/\d+\/auth/).reply(HttpStatus.OK);

  // Let everything else through
  mock.onAny().passThrough();
}

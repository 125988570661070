/* eslint-disable react/jsx-props-no-spreading */
import { QTag } from '@qualio/ui-components';
import React from 'react';
import styled from 'styled-components';

export type TagProps = {
  label: string;
  clickHandler?: (...args: any[]) => void;
};

const Span = styled.span`
  cursor: pointer;
  &:hover span {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
    background: #666666;
  }
`;

export const Tag = ({ label, clickHandler }: TagProps) => {
  return (
    <Span {...(clickHandler && { onClick: () => clickHandler(label), role: 'button' })}>
      <QTag>{label}</QTag>
    </Span>
  );
};

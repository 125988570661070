import { QText, useToastProvider } from '@qualio/ui-components';
import React, { SyntheticEvent, useState } from 'react';
import { AxiosError } from 'axios';
import { ConfirmationModal } from '../../containers/confirmation-modal';
import { Group, ModalComponentProps } from '../../types';
import { createSuccessToast, createErrorToast } from '../../utils/toast.utils';
import { NoBtnLabel } from '../../__displayStrings__/buttonsLabels';
import { deleteGroup } from '../../api/medtech-api';

type DeleteGroupModalProps = ModalComponentProps & {
  group: Group;
  setRefetch: () => Promise<void>;
};

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({ closeModal, group, setRefetch }) => {
  const { showToast } = useToastProvider();
  const [isDeletingGroup, setDeletingGroup] = useState(false);
  const handleDeleteGroup = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletingGroup(true);
    try {
      await deleteGroup(group.id);
      showToast(createSuccessToast('Successfully deleted group!'));
      setRefetch();
      closeModal();
    } catch (error) {
      setDeletingGroup(false);
      const axiosError = error as AxiosError;

      const defaultErrorMessage = 'Something went wrong! Unable to delete group.';
      if (axiosError.response && axiosError.response.status === 409) {
        const responseData = axiosError.response.data as { error: string };
        const message = responseData.error || defaultErrorMessage;
        showToast(createErrorToast(message));
      } else {
        showToast(createErrorToast(defaultErrorMessage));
      }
    }
  };
  return (
    <ConfirmationModal
      closeModal={closeModal}
      title="Confirm delete group"
      cancelLabel={NoBtnLabel}
      buttonProps={{
        isLoading: isDeletingGroup,
        onClick: handleDeleteGroup,
        label: 'Yes, delete group',
        isDestructive: true,
      }}
    >
      <QText>
        Are you sure you want to delete &quot;{group.name}&quot; group? Deleting a group that is part of a Qualio
        Training Plan can impact your training assignments. Please check the Training Matrix to identify impacted plans.
      </QText>
    </ConfirmationModal>
  );
};

import { QText } from '@qualio/ui-components';
import React from 'react';
import { ConfirmationModal } from '../../../containers/confirmation-modal';
import { ModalComponentProps } from '../../../types';
import * as DisplayStrings from './__displayStrings__/CancelInviteConfirmation';
import { NoBtnLabel } from '../../../__displayStrings__/buttonsLabels';

type CancelInviteConfirmationProps = ModalComponentProps & {
  removeUser: () => void;
  isDeleting: boolean;
};

export const CancelInviteConfirmation = ({ closeModal, removeUser, isDeleting }: CancelInviteConfirmationProps) => {
  return (
    <ConfirmationModal
      closeModal={closeModal}
      title={DisplayStrings.CancelInvitationTitle}
      size="lg"
      cancelLabel={NoBtnLabel}
      buttonProps={{
        type: 'button',
        label: DisplayStrings.CancelInvitationButtonLabel,
        isDestructive: true,
        onClick: removeUser,
        isLoading: isDeleting,
      }}
    >
      <QText>{DisplayStrings.CancelInvitationMessage()}</QText>
    </ConfirmationModal>
  );
};

import React from 'react';
import { QStack } from '@qualio/ui-components';
import CheckBox from '../CheckBox';
import { AccessSuppliersText } from './__displayStrings__';

export type SuppliersPermissionsFormFields = {
  canAccessSuppliers: boolean;
};

const SuppliersPermissions = () => {
  return (
    <QStack direction={['column']}>
      <CheckBox
        data-cy="permission-checkbox"
        name="canAccessSuppliers"
        label="suppliers access"
        text={AccessSuppliersText}
      />
    </QStack>
  );
};

export default SuppliersPermissions;

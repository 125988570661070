import { QFormControl, QInput, QCheckbox } from '@qualio/ui-components';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

type GroupFormProps = {
  handleSubmit: (e: SyntheticEvent) => void;
  currentGroupNames: string[];
  groupName: string;
  isIncludedInReports: boolean;
  setGroupName: Function;
  setIncludeInReports: Function;
  setFormValid: Function;
};

export const GroupForm: React.FC<GroupFormProps> = ({
  handleSubmit,
  groupName,
  isIncludedInReports,
  currentGroupNames,
  setGroupName,
  setIncludeInReports,
  setFormValid,
}) => {
  const init = useRef(true);
  const [groupErrorMsg, setGroupErrorMsg] = useState('');
  const [formValidation, setFormValidation] = useState({
    isValidGroupName: true,
  });

  const validateGroupName = (): boolean => {
    const isValidLength = !!groupName.trim();
    const isUniqueGroupName = !currentGroupNames.includes(groupName.toLowerCase().trim());
    if (!isValidLength) {
      setGroupErrorMsg('Minimum length of 1 character required.');
    }
    if (!isUniqueGroupName) {
      setGroupErrorMsg(`Group with name "${groupName.trim()}" already exists.`);
    }
    setFormValidation({ isValidGroupName: isValidLength && isUniqueGroupName });
    setFormValid(isValidLength && isUniqueGroupName);
    return isValidLength && isUniqueGroupName;
  };

  useEffect(() => {
    if (init.current && !groupName) {
      init.current = false;
      return;
    }
    validateGroupName();
    init.current = false;
  }, [groupName]);

  return (
    <form id="groupForm" aria-label="group form" onSubmit={handleSubmit}>
      <QFormControl pb="2" label="Group name" size="sm" helper={!formValidation.isValidGroupName ? groupErrorMsg : ''}>
        <QInput
          data-cy="name-input"
          placeholder="Group name"
          aria-label="group name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          isInvalid={!formValidation.isValidGroupName}
        />
      </QFormControl>
      <QFormControl size="sm">
        <QCheckbox
          data-cy="in-reports-checkbox"
          aria-label="include in reports"
          isChecked={isIncludedInReports}
          onChange={(e) => setIncludeInReports(e.target.checked)}
        >
          Include in reports
        </QCheckbox>
      </QFormControl>
    </form>
  );
};

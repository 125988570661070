export const GeneralHeader = 'General';
export const QEPermissionsHeader = 'Events permissions';
export const QEDescriptionText =
  'An event requires a pre-defined action or response and tracking in accordance with regulatory requirements.';
export const QEPermissionsWarningText = 'View events must be selected in order to enable additional permissions';

export const NoRolePermissions = 'None';

export const PermissionsHeader = 'Permissions';
export const PermissionsEventsHeader = 'Events';
export const PermissionsEventsDescriptionText =
  'The Events feature enables your team to create and manage all quality events from root cause to verification of effectiveness';
export const PermissionsSuppliersHeader = 'Suppliers';
export const PermissionsSuppliersDescriptionText =
  'The Suppliers feature enables your team to configure your supplier policy, manage your Approved Supplier List, and store supplier audit documents';

export const PermissionsDesignControlsHeader = 'Design Controls';

export const PermissionDesignControlsDescriptionText =
  'The Design Controls feature enables efficient management and documentation of your product’s design and development process, ensuring compliance and quality.';

export const PermissionAPICapabilitiesHeader = 'API Capabilities';

export const PermissionAPICapabilitiesDescriptionText =
  'API Capabilities enables access to programmable interfaces for seamless integration and automation of your enterprise systems with Qualio documents and training.';
export const ResetPermissionsText = 'Reset to default permissions';
export const LearnMoreAboutPermissionsText = 'Learn more about permissions.';

import { QBox, deprecated, QInput, QSelectItem, QFlex } from '@qualio/ui-components';
import React, { ReactElement, useMemo, useState } from 'react';
import { Group, User, UserQMenuItemType } from '../../../types';
import { createTableData } from '../../../utils/users.utils';
import { buildColumns } from './utils';
import * as DisplayStrings from './__displayStrings__';
import { MultiSelect } from '../../../components/MultiSelect';
import { formatGroupsToSelectOptions } from '../../../utils/groups.utils';

type StateSetterFunction<T> = React.Dispatch<React.SetStateAction<T>>;

type UserListProps = {
  users: User[];
  selectedUserIds: Set<number>;
  setSelectedUserIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  groups: Group[];
  selectedGroups: QSelectItem[];
  setSelectedGroups: StateSetterFunction<QSelectItem[]>;
  handleUserAction: (actionItem: UserQMenuItemType) => void;
  filterByGroup: (groupName?: string) => void;
};

export const UserList = ({
  users,
  selectedUserIds,
  setSelectedUserIds,
  groups,
  selectedGroups,
  setSelectedGroups,
  handleUserAction,
  filterByGroup,
}: UserListProps): ReactElement => {
  const groupMenuItems = formatGroupsToSelectOptions(groups);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredUsers = useMemo(() => {
    if (!searchQuery) return users;
    return users.filter(
      (user) =>
        user.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [users, searchQuery]);
  const data = useMemo(
    () => createTableData(filteredUsers, handleUserAction, filterByGroup, selectedUserIds, setSelectedUserIds),
    [filteredUsers, selectedUserIds],
  );

  const areAllVisibleUsersSelected = useMemo(() => {
    const visibleUserIds = users.map((u) => u.id);
    const anyUsersSelected = selectedUserIds.size > 0;
    return anyUsersSelected && visibleUserIds.every((id) => selectedUserIds.has(id));
  }, [users, selectedUserIds]);

  const columns = useMemo(() => {
    const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        setSelectedUserIds(new Set<number>(users.map(({ id }) => id)));
      } else {
        setSelectedUserIds(new Set());
      }
    };

    return buildColumns(areAllVisibleUsersSelected, handleHeaderCheckboxChange);
  }, [areAllVisibleUsersSelected, selectedUserIds, setSelectedUserIds, users]);

  return (
    <QBox>
      <QFlex gap="8px" mb="1em">
        <MultiSelect
          onChange={(multiSelectedGroups) => setSelectedGroups(multiSelectedGroups as QSelectItem<string, string>[])}
          value={selectedGroups.map((group) => group.value)}
          options={groupMenuItems}
          fixedWidth="250px"
          placeholder={DisplayStrings.FilterByGroupLabel}
        />
        <QInput
          placeholder="Search users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          data-cy="search-users-input"
        />
      </QFlex>
      <deprecated.QDataTable columns={columns} data={data} hasPagination={false} />
    </QBox>
  );
};

import React from 'react';
import { QAlert, QHeading, QStack, QText, QButton, QLink, QBox } from '@qualio/ui-components';
import {
  QEPermissionsWarningText,
  PermissionsHeader,
  PermissionsEventsHeader,
  PermissionsEventsDescriptionText,
  ResetPermissionsText,
  PermissionsSuppliersHeader,
  PermissionsSuppliersDescriptionText,
  LearnMoreAboutPermissionsText,
} from './__displayStrings__';
import QualityEventsPermissions from '../../../components/FormComponents/QualityEventsPermissions';
import SuppliersPermissions from '../../../components/FormComponents/SuppliersPermissions';
import { UserPermissionsInfoLink } from '../../../constants';

type SuppliersAndQEPermissionsSectionProps = {
  resetPermissions: () => void;
};

const SuppliersAndQEPermissionsSection = ({ resetPermissions }: SuppliersAndQEPermissionsSectionProps) => {
  return (
    <QStack direction={['column']} spacing={4}>
      <QHeading fontWeight="bold" size="md" mt={2}>
        {PermissionsHeader}
      </QHeading>
      <QStack direction="column">
        <QHeading mt={2} size="sm">
          {PermissionsEventsHeader}
        </QHeading>
        <QText color="gray.500">{PermissionsEventsDescriptionText}</QText>
      </QStack>
      <QAlert description={QEPermissionsWarningText} status="info" />
      <QualityEventsPermissions />
      <QHeading fontWeight="bold" size="sm">
        {PermissionsSuppliersHeader}
      </QHeading>
      <QText color="gray.500">{PermissionsSuppliersDescriptionText}</QText>
      <SuppliersPermissions />
      <QButton data-cy="reset-button" variant="outline" size="sm" onClick={resetPermissions}>
        {ResetPermissionsText}
      </QButton>
      <QBox fontSize="14px">
        <QLink data-cy="about-permissions-link" isExternal href={UserPermissionsInfoLink}>
          {LearnMoreAboutPermissionsText}
        </QLink>
      </QBox>
    </QStack>
  );
};

export default SuppliersAndQEPermissionsSection;

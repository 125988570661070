import React from 'react';
import { ModalComponentProps, User } from '../../../types';
import { isUserInviteAccepted } from '../../../utils/users.utils';
import { CancelInviteConfirmation } from './CancelInviteConfirmation';
import { DeleteUserConfirmation } from './DeleteUserConfirmation';

type RemoveUserModalProps = ModalComponentProps & {
  removeUser: () => void;
  isDeleting: boolean;
  user: User;
};

const RemoveUserModal = ({ closeModal, removeUser, isDeleting, user }: RemoveUserModalProps) => {
  const isActiveUser = isUserInviteAccepted(user);
  const { full_name: fullName, email } = user;
  const displayName = fullName || email || '';

  return isActiveUser ? (
    <DeleteUserConfirmation
      closeModal={closeModal}
      removeUser={removeUser}
      isDeleting={isDeleting}
      name={displayName}
    />
  ) : (
    <CancelInviteConfirmation closeModal={closeModal} removeUser={removeUser} isDeleting={isDeleting} />
  );
};

export default RemoveUserModal;

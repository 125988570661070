import React from 'react';
import { QStack } from '@qualio/ui-components';
import { useFormikContext } from 'formik';
import CheckBox from '../CheckBox';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { ViewText, CreateText, EditText, ManageText } from '../../../__displayStrings__/displayStrings';

export type QualityEventsPermissionsFormFields = {
  canViewQE: boolean;
  canCreateQE: boolean;
  canEditQE: boolean;
  canManageQE: boolean;
};

const QualityEventsPermissions = () => {
  const { values, setFieldValue } = useFormikContext<QualityEventsPermissionsFormFields>();
  const { canViewQE } = values;
  const checkboxesDisabled = !canViewQE;

  useUpdateEffect(() => {
    if (!canViewQE) {
      setFieldValue('canCreateQE', false);
      setFieldValue('canEditQE', false);
      setFieldValue('canManageQE', false);
    }
  }, [canViewQE]);

  return (
    <QStack direction={['column']}>
      <CheckBox data-cy="permission-checkbox" name="canViewQE" label="can view quality events" text={ViewText} />
      <QStack direction={['column']} paddingLeft="2rem" paddingRight="2rem">
        <CheckBox
          data-cy="permission-checkbox"
          name="canCreateQE"
          label="can create quality events"
          text={CreateText}
          isDisabled={checkboxesDisabled}
        />
        <CheckBox
          data-cy="permission-checkbox"
          name="canEditQE"
          label="can edit quality events"
          text={EditText}
          isDisabled={checkboxesDisabled}
        />
        <CheckBox
          data-cy="permission-checkbox"
          name="canManageQE"
          label="can manage quality events"
          text={ManageText}
          isDisabled={checkboxesDisabled}
        />
      </QStack>
    </QStack>
  );
};

export default QualityEventsPermissions;

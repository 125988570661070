import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useState } from 'react';
import { updateUsers } from '../../api/um-api';
import { ErrorCodes, getErrorMessageOrDefault, getUMErrorResponseMessageOrDefault } from '../../errors/um-errors';
import { MinimumRequiredQualityUsersErrorMessage } from '../../errors/__displayStrings__';
import { ModalComponentProps, User } from '../../types';
import { MedtechRole } from '../../types/medtechRole.types';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import { UsergroupsList } from '../../utils/umfe.enums';
import { isBillingUser } from '../../utils/users.utils';
import {
  CancelBtnLabel,
  SetRoleModalTitle,
  SubmitBtnLabel,
  UpdateUserRoleFailedMessage,
  UpdateUserRoleSuccessMessage,
} from './__displayStrings__/SetRoleModal';

type SetRoleModalProps = ModalComponentProps & {
  getUsers: () => Promise<void>;
  users: User[];
  companyId: number;
};

export const SetRoleModal = ({ closeModal, users, getUsers, companyId }: SetRoleModalProps) => {
  const [role, setRole] = useState(Object.values(UsergroupsList)[0].value);

  const [isLoading, setLoading] = useState(false);
  const { showToast } = useToastProvider();

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    const updateUsersPayload = users.map((user: User) => ({
      userId: user.id,
      isBilling: isBillingUser(user),
      role,
    }));

    updateUsers(companyId, updateUsersPayload)
      .then(async () => {
        await getUsers();
        showToast(createSuccessToast(UpdateUserRoleSuccessMessage(users.length)));
        setLoading(false);
        closeModal();
      })
      .catch((error: unknown) => {
        const errorMessageMap = {
          [ErrorCodes.MINIMUM_QM_USER_REQUIRED]: MinimumRequiredQualityUsersErrorMessage,
          [ErrorCodes.INVALID_USER_UPDATE]: getUMErrorResponseMessageOrDefault(error, UpdateUserRoleFailedMessage),
        };

        const errorMessage = getErrorMessageOrDefault(error, errorMessageMap, UpdateUserRoleFailedMessage);

        showToast(createErrorToast(errorMessage));
        setLoading(false);
      });
  };

  const setSelectOptions = Object.values(UsergroupsList).map(({ label, value }) => {
    return {
      label,
      value,
    };
  });

  return (
    <QModal onClose={closeModal} size="md" isOpen>
      <QModalHeader>
        <QText data-cy="heading">{SetRoleModalTitle}</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>
        <QSelect
          data-cy={role}
          aria-label="role"
          value={role}
          onChange={(e) => e && setRole(e.value as MedtechRole)}
          options={setSelectOptions}
        />
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-button" variant="outline" onClick={closeModal}>
          {CancelBtnLabel}
        </QButton>
        <QButton data-cy="submit-button" isLoading={isLoading} onClick={handleSubmit}>
          {SubmitBtnLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

import React, { SyntheticEvent, useState } from 'react';
import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { AxiosError } from 'axios';
import { CreateGroupPayload, GroupRowData, ModalComponentProps } from '../../types';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import { GroupForm } from '../forms/GroupForm';
import { createGroup } from '../../api/medtech-api';

type CreateGroupModalProps = ModalComponentProps & {
  currentGroups: GroupRowData[];
  setRefetch: () => void;
};

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ closeModal, currentGroups, setRefetch }) => {
  const { showToast } = useToastProvider();
  const currentGroupNames = currentGroups.map((g) => g.name.toLowerCase());
  const [groupName, setGroupName] = useState('');
  const [isIncludedInReports, setIncludeInReports] = useState(true);
  const [isCreatingGroup, setCreatingGroup] = useState(false);
  const [isFormValid, setFormValid] = useState(false);

  const handleCreateGroup = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Return early if user pressed 'enter' when form is invalid
    if (!isFormValid) {
      return;
    }
    setCreatingGroup(true);
    const newGroup: CreateGroupPayload = {
      name: groupName.trim(),
      include_in_reports: isIncludedInReports,
    };
    try {
      await createGroup(newGroup);
      setCreatingGroup(false);
      showToast(createSuccessToast('Successfully created group!'));
      setRefetch();
      closeModal();
    } catch (error) {
      const axiosError = error as AxiosError;
      setCreatingGroup(false);
      showToast(createErrorToast(axiosError.message || 'Something went wrong! Unable to create group.'));
    }
  };

  return (
    <QModal isOpen onClose={closeModal}>
      <QModalHeader>
        <QText>Create group</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>
        <GroupForm
          handleSubmit={handleCreateGroup}
          currentGroupNames={currentGroupNames}
          groupName={groupName}
          setGroupName={setGroupName}
          isIncludedInReports={isIncludedInReports}
          setIncludeInReports={setIncludeInReports}
          setFormValid={setFormValid}
        />
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-button" variant="outline" onClick={closeModal}>
          Cancel
        </QButton>
        <QButton
          data-cy="confirm-button"
          isDisabled={!isFormValid || !groupName}
          isLoading={isCreatingGroup}
          onClick={handleCreateGroup}
        >
          Confirm
        </QButton>
      </QModalActions>
    </QModal>
  );
};

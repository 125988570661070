import { ReactNode } from 'react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { CurrentUser } from '@qualio/ui-components';
import { environment } from '../environments/environment';

/**
 * @description Stores a map of components to LD flags
 */
const ldFlagMap = {
  users: 'umEnableUsersPage',
  groups: 'umEnableGroupsPage',
  umQeSelfService: 'umQeSelfService',
  umSuppliersSelfService: 'umSuppliersSelfService',
  adminCapabilitiesPermissions: 'adminCapabilitiesExtendedPermissions',
  qualioLabs: 'qualioLabs',
  termsOfService: 'termsOfService',
  umDualWrite: 'umDualWrite',
} as const;

export type KnownFlags = keyof typeof ldFlagMap;

/**
 * This function assess if the component being rendered has an active flag in ld flag set
 * @param ldFlagSet
 * @param component
 * @returns boolean
 */
const isLdEnabled = (ldFlagSet: LDFlagSet, component: KnownFlags) => {
  const flag = ldFlagMap[component];

  return flag in ldFlagSet ? Boolean(ldFlagSet[flag]) : false;
};

/**
 * LD primary setup with asyncWithLDProvider
 */
let flagProviderPromise: Promise<({ children }: { children: ReactNode }) => JSX.Element>;
export const initFlagProvider = (currentUser: CurrentUser) => {
  flagProviderPromise = asyncWithLDProvider({
    clientSideID: environment.ldClientSideId,
    context: {
      kind: 'user',
      key: `${currentUser.companyId}`,
      loggedInUserId: String(currentUser.userId),
      status: currentUser.company.status,
      createdTime: currentUser.company.createdTime,
    },
  });
};

export const FlagProviderFactory = () => flagProviderPromise;

export function useFlagProvider() {
  const umFlags = useFlags();

  return {
    isFlagEnabled: (component: KnownFlags) => isLdEnabled(umFlags, component),
  };
}

import { useQuery } from 'react-query';
import { authenticate } from '../api/auth-exchange.api';
import { environment } from '../environments/environment';
import { CacheKeys } from '../types';

export const useAuthExchangeHook = (companyId: number) => {
  return useQuery([CacheKeys.AUTHENTICATION, companyId], () => authenticate(companyId), {
    refetchInterval: environment.authSuccessRefreshIntervalMillis,
  });
};

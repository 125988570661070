import React, { useCallback } from 'react';
import { QBox, QCenter, QDivider, QStack } from '@qualio/ui-components';
import { useFormikContext } from 'formik';
import { MedtechRole } from '../../../types/medtechRole.types';
import { MedtechPermissionsByRolesMap } from '../../../utils/validation/permissions';
import GeneralSection from './GeneralSection';
import QEPermissionsSection from './QEPermissionsSection';
import SuppliersAndQEPermissionsSection from './SuppliersAndQEPermissionsSection';
import {
  CanCreateIssue,
  CanManageQualityEventWorkflow,
  CanViewQE,
  CanWorkOnIssue,
  CanAccessSuppliers,
  CanAccessAPICapabilities,
  CanAccessDesignControls,
} from '../../../utils/validation/medtechPermissions';
import RoleWatcher from './RoleWatcher';
import { useFlagProvider } from '../../../utils/flagProvider';
import { PermissionsSection } from './PermissionsSection';

type EditUserFormProps = {
  inviteIsPending: boolean;
  showQESection: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  userRole: MedtechRole;
  defaultPermissions: MedtechPermissionsByRolesMap;
  onCancel: () => void;
};

const EditUserForm = ({
  inviteIsPending,
  showQESection,
  isLoading,
  isDisabled,
  defaultPermissions,
  userRole,
  onCancel,
}: EditUserFormProps) => {
  const { setFieldValue } = useFormikContext();
  const defaultPermissionsByRole = defaultPermissions[userRole];
  const suppliersFlagEnabled = useFlagProvider().isFlagEnabled('umSuppliersSelfService');
  const showAdminCapabilities = useFlagProvider().isFlagEnabled('adminCapabilitiesPermissions');

  const resetPermissions = useCallback(() => {
    setFieldValue('canViewQE', defaultPermissionsByRole.includes(CanViewQE));
    setFieldValue('canCreateQE', defaultPermissionsByRole.includes(CanCreateIssue));
    setFieldValue('canEditQE', defaultPermissionsByRole.includes(CanWorkOnIssue));
    setFieldValue('canManageQE', defaultPermissionsByRole.includes(CanManageQualityEventWorkflow));

    if (suppliersFlagEnabled) {
      setFieldValue('canAccessSuppliers', defaultPermissionsByRole.includes(CanAccessSuppliers));
    }

    if (showAdminCapabilities) {
      setFieldValue('canAccessAPICapabilities', defaultPermissionsByRole.includes(CanAccessAPICapabilities));
      setFieldValue('canAccessDesignControls', defaultPermissionsByRole.includes(CanAccessDesignControls));
    }
  }, [setFieldValue, defaultPermissionsByRole]);

  return (
    <QCenter>
      <QBox maxWidth="40rem">
        <QStack direction={['column']}>
          <GeneralSection inviteIsPending={inviteIsPending} />
          <QDivider />
          {showAdminCapabilities ? (
            <>
              <RoleWatcher resetPermissions={resetPermissions} role={userRole} />
              <PermissionsSection
                resetPermissions={resetPermissions}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onCancel={onCancel}
              />
            </>
          ) : (
            showQESection && (
              <>
                <RoleWatcher resetPermissions={resetPermissions} role={userRole} />
                {suppliersFlagEnabled ? (
                  <SuppliersAndQEPermissionsSection resetPermissions={resetPermissions} />
                ) : (
                  <QEPermissionsSection defaultPermissions={defaultPermissions} />
                )}
              </>
            )
          )}
        </QStack>
      </QBox>
    </QCenter>
  );
};

export default EditUserForm;

import { InviteStatusMap, User } from '../types';
import {
  CanAccessBilling,
  CanAccessSuppliers,
  CanCreateIssue,
  CanManageQualityEventWorkflow,
  CanViewQE,
  CanWorkOnIssue,
} from '../utils/validation/medtechPermissions';
import { MTBEUserType } from '../utils/validation/mtbe-user';
import { UserV2Type } from '../utils/validation/user';
import { mockedGroupsResponse } from './groups.mock';

export const getBaseUser = (props?: Partial<User>) => ({
  id: 1,
  email: 'base@example.com',
  invite_status: 'accepted',
  full_name: 'base user',
  role: 'read_only',
  groups: [
    {
      id: mockedGroupsResponse.data[0].id,
      include_in_reports: mockedGroupsResponse.data[0].include_in_reports,
      name: mockedGroupsResponse.data[0].name,
    },
  ],
  access: [],
  isAdmin: false,
  permissions: [
    {
      can_access_billing: false,
    },
  ],
  ...props,
});

export const getBaseV2User = (props?: Partial<UserV2Type>): UserV2Type => ({
  id: 1,
  email: 'base@example.com',
  fullName: 'base user',
  staff: false,
  createdTime: 123456789,
  inviteStatus: 'accepted',
  active: true,
  groups: [],
  role: 'read_only',
  tz: 'America/New_York',
  medtechPermissions: {
    [CanAccessBilling]: false,
    [CanViewQE]: false,
    [CanCreateIssue]: false,
    [CanWorkOnIssue]: false,
    [CanManageQualityEventWorkflow]: false,
    [CanAccessSuppliers]: false,
  },
  isAdmin: false,
  ...props,
});

export const mockedInvitedUser = {
  ...getBaseUser(),
  id: 12,
  email: 'newone@example.com',
  invite_status: 'pending',
  full_name: 'Mr Foo Bar',
  role: 'normal',
  access: ['Billing'],
};

export const mockedAcceptedUser = {
  ...getBaseUser(),
  id: 13,
  email: 'test1@example.com',
  invite_status: 'accepted',
  full_name: 'test1',
  role: 'quality',
};

export const mockInvitedUserNoName = {
  ...getBaseUser(),
  id: 14,
  email: 'emptyname@example.com',
  invite_status: 'pending',
  full_name: '',
  role: 'read_only',
  groups: [
    {
      id: 1,
      name: 'meaningless',
      include_in_reports: false,
    },
  ],
};

export const mockedPendingUser = {
  ...getBaseUser(),
  id: 15,
  email: 'pendingperson@example.com',
  invite_status: 'pending',
  full_name: 'pending person',
  role: 'read_only',
  groups: [
    {
      id: 1,
      name: 'interns',
      include_in_reports: false,
    },
    {
      id: 2,
      name: 'management',
      include_in_reports: false,
    },
  ],
};

export const mockedDeclinedUser = {
  ...getBaseUser(),
  id: 16,
  email: 'declined@example.com',
  invite_status: 'declined',
  full_name: 'declined user',
  role: 'read_only',
};

export const mockedUsersResponse = {
  data: [mockedAcceptedUser, mockedInvitedUser, mockInvitedUserNoName, mockedDeclinedUser, mockedPendingUser],
};

export const mockedUsersCount = {
  data: {
    active_users_count: 9,
    active_users_limit: 10,
    id: 1,
    logo: null,
    name: 'Darwin Med Tech',
    passwords_expire_in: null,
    sso_domain: null,
    sso_enabled: null,
    sso_turned_on: false,
    status: 'active',
    total_users_count: 11,
    total_users_limit: 12,
    training_completion_period: 14,
    trial_ends_at: null,
  },
};

export const getBaseMTBEUser = (user: Partial<MTBEUserType> = {}) => ({
  id: 1,
  email: 'testuser@mtbe.com',
  full_name: 'MTBE user',
  staff: false,
  companies: [
    {
      id: 100,
      active: true,
      currently_selected: true,
      invite_status: InviteStatusMap.accepted,
      isAdmin: false,
      name: 'MTBE Company',
      permissions: [],
      pricing_plan: null,
      usergroups: ['read_only'],
    },
  ],
  ...user,
});

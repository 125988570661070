import { QButton, QSpinner, QStack, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  fetchUsergroupPermissions,
  getCompanyAdminSettings,
  patchCompanyAdminSettings,
  updateUsergroupPermission,
} from '../../../api/medtech-api';
import { MedtechRole, MedtechRolesMap } from '../../../types/medtechRole.types';
import { CanReviewDocuments } from '../../../utils/validation/medtechPermissions';
import { PermissionControl } from './PermissionsControl';
import { ManageableUsergroupPermission, ManageableUsergroupPermissionsByRoleMap } from './types';

export const Permissions = () => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const isBasicUsersReviewDocsCapabilityEnabled = currentUser.capabilities?.basic_users_review_docs;
  const isAiCapabilityEnabled = (currentUser.capabilities as any)?.ai === true;
  const [isLoading, setLoading] = useState(true);
  const [isGhostEnabled, setIsGhostEnabled] = useState(false);
  const [aiEnabledValue, setAiEnabledValue] = useState(false);
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const [permissions, setPermissions] = useState<ManageableUsergroupPermissionsByRoleMap>({
    [MedtechRolesMap.basic]: {
      [CanReviewDocuments]: false,
    },
  });

  useEffect(() => {
    const fetchAdminSettings = async () => {
      setLoading(true);
      try {
        const response = await getCompanyAdminSettings(currentUser.companyId);
        setAiEnabledValue(response.data.ai_enabled);
        setIsGhostEnabled(response.data.allow_ghost);
      } catch (error) {
        showToast({
          id: 'get-admin-settings',
          replace: true,
          title: 'Error',
          description: 'Failed to load settings.',
          status: 'error',
        });
      }
      setLoading(false);
    };

    fetchAdminSettings();
  }, [currentUser.companyId, showToast]);

  const getUsergroupPermissions = () => {
    return fetchUsergroupPermissions(currentUser.companyId).then((data) => {
      setPermissions((currentValue) => {
        return {
          ...currentValue,
          [MedtechRolesMap.basic]: {
            [CanReviewDocuments]: data[MedtechRolesMap.basic].some(
              (permission) => permission.permission_id === CanReviewDocuments,
            ),
          },
        };
      });
      return data;
    });
  };

  const { isLoading: isLoadingUsergroupPermissions } = useQuery({
    queryKey: ['usergroup-permissions', currentUser.companyId],
    queryFn: getUsergroupPermissions,
    refetchOnWindowFocus: false,
    enabled: isBasicUsersReviewDocsCapabilityEnabled,
  });

  if (isLoading || (isBasicUsersReviewDocsCapabilityEnabled && isLoadingUsergroupPermissions)) {
    return <QSpinner size="sm" />;
  }

  const updateGhostValue = (allowGhost: boolean) => {
    return patchCompanyAdminSettings(currentUser.companyId, { allow_ghost: allowGhost });
  };

  const updateCompanyAiEnabled = (aiEnabled: boolean) => {
    return patchCompanyAdminSettings(currentUser.companyId, { ai_enabled: aiEnabled });
  };

  const updateUsergroupPermissions = () => {
    const updates = Object.entries(permissions).reduce((acc: Promise<void>[], [usergroup, usergroupPermissionsMap]) => {
      const usergroupPermissionUpdates = Object.entries(usergroupPermissionsMap).reduce(
        (all: Promise<void>[], [permission, isEnabled]) => {
          return all.concat(
            updateUsergroupPermission(
              currentUser.companyId,
              usergroup as MedtechRole,
              permission as ManageableUsergroupPermission,
              isEnabled,
            ),
          );
        },
        [],
      );
      return [...acc, ...usergroupPermissionUpdates];
    }, []);
    return Promise.all(updates);
  };

  const onApplyChanges = async () => {
    setIsApplyingChanges(true);
    const updates: Promise<any>[] = [updateGhostValue(isGhostEnabled)];

    if (isBasicUsersReviewDocsCapabilityEnabled) {
      updates.push(updateUsergroupPermissions());
    }
    if (isAiCapabilityEnabled) {
      updates.push(updateCompanyAiEnabled(aiEnabledValue));
    }

    await Promise.all(updates)
      .then(() => {
        showToast({
          id: 'update-permissions',
          replace: true,
          title: 'Instance permissions',
          description: 'Instance permissions successfully updated',
          status: 'success',
        });
      })
      .catch(() => {
        showToast({
          id: 'update-permissions',
          replace: true,
          title: 'Instance permissions',
          description: 'Failed to update instance permissions',
          status: 'error',
        });
      });
    setIsApplyingChanges(false);
  };

  const handleGhostCheckboxChange = (configId: string, enabled: boolean) => {
    setIsGhostEnabled(enabled);
  };

  const handleAiCheckboxChange = (configId: string, enabled: boolean) => {
    setAiEnabledValue(enabled);
  };

  const handleUsergroupPermissionCheckboxChange = (
    usergroup: keyof ManageableUsergroupPermissionsByRoleMap,
    permission: string,
    isEnabled: boolean,
  ) => {
    setPermissions((currentValue) => {
      return {
        ...currentValue,
        [usergroup]: {
          ...currentValue[usergroup],
          [permission]: isEnabled,
        },
      };
    });
  };

  return (
    <QStack spacing="8">
      {isAiCapabilityEnabled && (
        <QStack>
          <PermissionControl
            label="AI-powered editing"
            description="Suggested training assessments, change controls and document improvements powered by AI."
            permissionId="ai_enabled"
            permissionLabel="Enable AI-powered editing"
            isChecked={aiEnabledValue}
            handleChange={handleAiCheckboxChange}
            data-cy="permissions-ai-checkbox"
          />
        </QStack>
      )}
      {isBasicUsersReviewDocsCapabilityEnabled && (
        <QStack>
          <PermissionControl
            label="Reviewers"
            description="Grant Basic users permission to be added as a reviewer on documents."
            permissionId={CanReviewDocuments}
            permissionLabel="All basic users can be reviewers"
            isChecked={permissions[MedtechRolesMap.basic][CanReviewDocuments]}
            handleChange={(_configId, enabled) =>
              handleUsergroupPermissionCheckboxChange(MedtechRolesMap.basic, CanReviewDocuments, enabled)
            }
            data-cy="permissions-basic-reviewers-checkbox"
          />
        </QStack>
      )}
      <PermissionControl
        label="Qualio support access"
        description="Allow Qualio support to access your instance in read-only mode."
        data-cy="instance-access-checkbox"
        permissionId="allow_ghost"
        permissionLabel="Enable support access"
        isChecked={isGhostEnabled}
        handleChange={handleGhostCheckboxChange}
      />
      <QButton isLoading={isApplyingChanges} onClick={onApplyChanges} data-cy="save-changes">
        Save changes
      </QButton>
    </QStack>
  );
};

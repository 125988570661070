import { QBox, QHeading, QHeadingProps } from '@qualio/ui-components';
import React from 'react';

type HeaderProps = {
  content: string;
  paddingBottom?: number;
  width?: string;
  fontSize?: QHeadingProps['size'];
};

/**
 * UM Header component to create component headers with consistent styling
 */
export const Header = ({ content, paddingBottom, width, fontSize }: HeaderProps) => {
  const pb = paddingBottom || 4;
  const w = width || '100%';
  const size = fontSize || '2xl';

  return (
    <QBox pb={pb} w={w}>
      <QHeading fontSize={size}>{content}</QHeading>
    </QBox>
  );
};

import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
  useToastProvider,
  useCurrentUser as useQCurrentUser,
} from '@qualio/ui-components';
import React from 'react';
import { Formik, Form } from 'formik';
import { updateUserById } from '../../api/um-api';
import { ModalComponentProps, UpdateUserByIdRequestBody, User } from '../../types';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import {
  isBillingUser,
  isUserChangingSelf,
  isUserCompanyAdmin,
  isUserInvitePending,
  isValidFullName,
} from '../../utils/users.utils';
import { validateEmail } from '../../utils/validation/email';
import { EditUser, UpdateUserFailedText, UpdateUserSuccessText } from './__displayStrings__/UserModal';
import { CancelBtnLabel, SaveBtnLabel } from '../../__displayStrings__/buttonsLabels';
import UserInfo, { UserInfoFormFields } from '../../components/FormComponents/UserInfo';
import { ErrorCodes, getErrorMessageOrDefault, getUMErrorResponseMessageOrDefault } from '../../errors/um-errors';
import {
  ActiveUsersLimitReachedErrorMessage,
  MinimumRequiredAdminsErrorMessage,
  MinimumRequiredQualityUsersErrorMessage,
} from '../../errors/__displayStrings__';
import redirect from '../../utils/redirect';
import { useFlagProvider } from '../../utils/flagProvider';
import { updateUser } from '../../api/medtech-api';

type UserModalProps = ModalComponentProps & {
  companyId: number;
  user: User;
  getUsers: () => Promise<void>;
};

type FormValues = UserInfoFormFields;

export const UserModal: React.FC<UserModalProps> = ({ closeModal, companyId, user, getUsers }) => {
  const { userId: currentUserId } = useQCurrentUser();
  const { showToast } = useToastProvider();
  const inviteIsPending = isUserInvitePending(user);
  const useMedtech = useFlagProvider().isFlagEnabled('umDualWrite');
  const initialValues: FormValues = {
    fullName: user.full_name,
    email: user.email,
    role: user.role,
    adminAccess: user.isAdmin,
    billingAccess: isBillingUser(user),
  };

  // formik form validator (updating once Heechul's branch is merged)
  const formValidation = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (inviteIsPending) {
      return errors;
    }

    if (!isValidFullName(values.fullName)) {
      errors.fullName = 'Invalid Full Name';
    }

    if (!validateEmail(values.email)) {
      errors.email = 'Invalid Email';
    }

    return errors;
  };

  const sendUserUpdate = async (user_id: number, payload: UpdateUserByIdRequestBody) => {
    if (useMedtech) {
      return updateUser(companyId, user_id, payload);
    }
    return updateUserById(companyId, user_id, payload);
  };

  const handleSave = async (values: FormValues) => {
    const payload = {
      fullName: values.fullName,
      email: values.email,
      role: values.role,
      isBilling: values.billingAccess,
      isAdmin: values.adminAccess,
    };

    try {
      const updatedUser = await sendUserUpdate(user.id, payload);

      if (!isUserCompanyAdmin(updatedUser, companyId) && isUserChangingSelf(currentUserId, user.id)) {
        redirect();
      }

      await getUsers();
      showToast(createSuccessToast(UpdateUserSuccessText));
      closeModal();
    } catch (err: unknown) {
      const errorMessageMap = {
        [ErrorCodes.MINIMUM_ADMINS_REQUIRED]: MinimumRequiredAdminsErrorMessage,
        [ErrorCodes.MINIMUM_QM_USER_REQUIRED]: MinimumRequiredQualityUsersErrorMessage,
        [ErrorCodes.INVALID_USER_UPDATE]: getUMErrorResponseMessageOrDefault(err, UpdateUserFailedText),
        [ErrorCodes.ACTIVE_USERS_LIMIT_REACHED]: ActiveUsersLimitReachedErrorMessage,
      };

      const errorMessage = getErrorMessageOrDefault(err, errorMessageMap, UpdateUserFailedText);

      showToast(createErrorToast(errorMessage));
    }
  };

  return (
    <Formik initialValues={initialValues} validate={formValidation} onSubmit={handleSave}>
      {({ dirty, isValid, isSubmitting, submitForm }) => {
        // save button should be disabled unless the form has changed and is valid
        const saveDisabled = !dirty || !isValid;

        return (
          <Form>
            <QModal onClose={closeModal} size="2xl" isOpen>
              <QModalHeader>
                <QText>{EditUser}</QText>
                <QCloseButton onClick={closeModal} />
              </QModalHeader>
              <QModalBody>
                <UserInfo inviteIsPending={inviteIsPending} />
              </QModalBody>
              <QModalActions>
                <QButton variant="outline" onClick={closeModal}>
                  {CancelBtnLabel}
                </QButton>
                <QButton onClick={submitForm} isLoading={isSubmitting} isDisabled={saveDisabled}>
                  {SaveBtnLabel}
                </QButton>
              </QModalActions>
            </QModal>
          </Form>
        );
      }}
    </Formik>
  );
};

import { QText } from '@qualio/ui-components';
import React, { ReactElement } from 'react';

type CommaSeparatedListOptions = {
  bold?: boolean;
  messageSuffix?: string | ReactElement;
  messagePrefix?: string | ReactElement;
};

/**
 * @description Returns a value in bold
 */
const boldenValue = (value: string) => <strong>{value}</strong>;

/**
 * @description Will render a string with values separated by commas
 * If provided suffix/prefix messages, then the strings will be appended
 * to the comma separated list
 *
 * Example without prefix/suffix messages:
 * <strong>Value 1</strong>, <strong>Value 2</strong>, and <strong>Value 3</strong>
 *
 * Example with prefix/suffix messages:
 * Values: <strong>Value 1</strong>, <strong>Value 2</strong>, and <strong>Value 3</strong> are separated by commas
 */
const createCommaSeparatedListMessage = (list: string[], options: CommaSeparatedListOptions) => {
  const { bold, messageSuffix, messagePrefix } = options;
  return (
    <QText>
      {messagePrefix || ''}
      {[...list].map((value, index, array) => {
        const renderedValue = bold ? boldenValue(value) : value;
        // If it's the last element in the array just need to return the name
        if (index + 1 === array.length) {
          return <span key={value}>{renderedValue}</span>;
        }

        // If it's a 2 element array it reads "value1 and value2"
        if (index + 2 === array.length && array.length === 2) {
          return <span key={value}>{renderedValue} and </span>;
        }

        // If it's more than 2 elements, the second to last element reads "valueN, and"
        if (index + 2 === array.length) {
          return <span key={value}>{renderedValue}, and </span>;
        }

        /**
         * The remaining options is that this an array of more than 2 and it is not
         * the last or second to last, so it reads "valueN, "
         */
        return <span key={value}>{renderedValue}, </span>;
      })}
      {messageSuffix ? ` ${messageSuffix}` : ''}
    </QText>
  );
};

export default createCommaSeparatedListMessage;

import { QStepVariantOptions } from '@qualio/ui-components';

/**
 * Used by the individual steps to update the step status.  "active" is not
 * included as the QStepper should be setting that itself automatically
 */
export type StepStatusOptions = Exclude<QStepVariantOptions, 'active'>;

export enum ReassignOwnerSummaryItemType {
  document = 'documents',
  changeRequest = 'change requests',
}

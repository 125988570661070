import React from 'react';
import { QStack } from '@qualio/ui-components';
import CheckBox from '../CheckBox';

export type APICapabilitiesPermissionsFormFields = {
  canAccessAPICapabilities: boolean;
};

const APICapabilitiesPermissions = () => {
  return (
    <QStack direction="column">
      <CheckBox
        data-cy="permission-checkbox"
        name="canAccessAPICapabilities"
        label="api capabilities"
        text="Access API Capabilities"
      />
    </QStack>
  );
};

export default APICapabilitiesPermissions;

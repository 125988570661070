import * as R from 'ramda';
import { CompanyInfo } from '../types';

type CompanyValidator = (company: CompanyInfo) => boolean;

const growthAndScalePlans = ['dx growth', 'tx/cx growth', 'dx scale', 'tx/cx scale'];

const getPricingPlan = R.pipe(R.prop<CompanyInfo['pricingPlan']>('pricingPlan'), R.defaultTo(''), R.toLower);
const getStatus = R.prop('status');

const planIsEmpty = (plan: string): boolean => !plan;
const planNotEmpty = R.complement(planIsEmpty);
const planIsStarter = (plan: string) => plan.includes('starter');
const planIsFoundation = (plan: string) => plan.includes('foundation');
const planIsGrowthOrScale = (plan: string) => growthAndScalePlans.includes(plan);

const companyHasNoPlan: CompanyValidator = R.pipe(getPricingPlan, planIsEmpty);
const companyIsStarter: CompanyValidator = R.pipe(getPricingPlan, R.allPass([planNotEmpty, planIsStarter]));
const companyIsFoundation: CompanyValidator = R.pipe(getPricingPlan, R.allPass([planNotEmpty, planIsFoundation]));
const companyIsGrowthOrScale: CompanyValidator = R.pipe(getPricingPlan, R.allPass([planNotEmpty, planIsGrowthOrScale]));
const companyIsActiveSandbox: CompanyValidator = R.pipe(getStatus, R.equals('active_sandbox'));

export const canManagePermissions: CompanyValidator = R.cond([
  [companyIsActiveSandbox, R.T],
  [companyHasNoPlan, R.F],
  [companyIsGrowthOrScale, R.T],
  [companyIsFoundation, R.F],
  [companyIsStarter, R.F],
  // Should only reach here if the plan is not in either predefined list and does not contain the word "starter"
  [R.T, R.T],
]);

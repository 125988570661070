import React from 'react';
import { QAlert, QCheckbox, QLink, QStack, QSubtitle, QText } from '@qualio/ui-components';

type PermissionsControlProps = {
  label: string;
  description: string;
  isChecked: boolean;
  handleChange: (configId: string, enabled: boolean) => void;
  permissionId: string;
  permissionLabel: string;
  'data-cy': string;
};

const PermissionControlLabel = ({ label, description }: Pick<PermissionsControlProps, 'label' | 'description'>) => {
  return (
    <QStack spacing="2">
      <QText fontWeight="semibold" fontSize="16px">
        {label}
      </QText>
      <QText fontSize="14px" color="gray.500">
        {description}
      </QText>
    </QStack>
  );
};

export const PermissionControl = ({
  label,
  description,
  permissionId,
  permissionLabel,
  handleChange,
  isChecked,
  ...props
}: PermissionsControlProps) => {
  return (
    <QStack spacing="4">
      <PermissionControlLabel label={label} description={description} />
      {permissionId === 'ai_enabled' && (
        <QSubtitle>
          <QAlert
            status="info"
            data-cy="tos-alert-permissions"
            isInline
            description={
              <QText data-cy="tos-text-permissions">
                By checking the box to enable this Qualio AI feature, you agree to be bound by Qualio&apos;s{' '}
                <QLink href="https://www.qualio.com/docs/terms" isExternal>
                  Terms of Service.
                </QLink>{' '}
              </QText>
            }
          />
        </QSubtitle>
      )}
      <QCheckbox
        data-cy={props['data-cy']}
        key={permissionId}
        onChange={() => handleChange(permissionId, !isChecked)}
        isChecked={isChecked}
      >
        {permissionLabel}
      </QCheckbox>
    </QStack>
  );
};

import React from 'react';
import { QAlert, QHeading, QStack, QText } from '@qualio/ui-components';
import { QEDescriptionText, QEPermissionsHeader, QEPermissionsWarningText } from './__displayStrings__';
import QualityEventsPermissions from '../../../components/FormComponents/QualityEventsPermissions';
import { QualityEventsPermissionsTable } from './QEDefaultPermissionsTable';
import { MedtechPermissionsByRolesMap } from '../../../utils/validation/permissions';

type QEPermissionSectionProps = {
  defaultPermissions: MedtechPermissionsByRolesMap;
};

const QEPermissionsSection = ({ defaultPermissions }: QEPermissionSectionProps) => {
  return (
    <QStack direction={['column']} spacing={4}>
      <QHeading fontWeight="bold" size="sm" mt={2}>
        {QEPermissionsHeader}
      </QHeading>
      <QText>{QEDescriptionText}</QText>
      <QualityEventsPermissionsTable qualityEventsDefaultPermissions={defaultPermissions} />
      <QAlert description={QEPermissionsWarningText} status="info" />
      <QualityEventsPermissions />
    </QStack>
  );
};

export default QEPermissionsSection;

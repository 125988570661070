import { QBadge, QCheckbox, QMenuButton, QMenuItemType } from '@qualio/ui-components';
import React from 'react';
import { Tag } from '../components/Tag';
import { User, UserQMenuItemType, UserTableData } from '../types/user.types';
import { BILLING_ACCESS } from '../constants';
import { getUserRoleLabel } from './translator';
import { UserActionItems } from './umfe.enums';
import { EMAIL_MAX_LENGTH } from './validation/email';
import { UserV2Type } from './validation/user';
import { CanAccessBilling } from './validation/medtechPermissions';
import { Group, InviteStatusMap } from '../types';
import { MedtechRolesMap } from '../types/medtechRole.types';
import { MTBEUserType } from './validation/mtbe-user';
import { MTBEUserCompanyType } from './validation/mtbe-user-company';

/**
 * @description: Creates a Qbadge from given values
 * @param invite_status
 * @returns: React.ReactElement
 */
export const formQbadge = (invite_status: string) => {
  if (!invite_status || invite_status === InviteStatusMap.accepted) {
    return '';
  }
  return <QBadge>{invite_status}</QBadge>;
};

/**
 * @description: Provides a way to map data form API to data usable in the users table
 * @param data
 * @returns: UserTableData[]
 */
export const createTableData = (
  data: User[],
  actionHandler: (itemClicked: UserQMenuItemType) => void,
  groupFilterAction: (selectedGroup: string) => void,
  selectedUserIds: Set<number>,
  setSelectedUserIds: (selectedUserIds: Set<number>) => void,
): UserTableData[] => {
  return data.map((user) => {
    const { id, email, invite_status: inviteStatus, full_name: fullName, role, groups, access } = user;

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSelectedUserIds = new Set([...selectedUserIds]);
      if (event.currentTarget.checked) {
        newSelectedUserIds.add(id);
      } else {
        newSelectedUserIds.delete(id);
      }
      setSelectedUserIds(newSelectedUserIds);
    };

    const baseActions = [
      {
        id: UserActionItems.EDIT_USER.id,
        label: UserActionItems.EDIT_USER.label,
        user,
      } as UserQMenuItemType,
    ];

    const cancelInviteMenuItem = {
      id: UserActionItems.CANCEL_INVITE.id,
      label: UserActionItems.CANCEL_INVITE.label,
      user,
    };
    const resendInviteMenuItem = {
      id: UserActionItems.RESEND_INVITE.id,
      label: UserActionItems.RESEND_INVITE.label,
      user,
    };
    const removeDeclinedMenuItem = {
      id: UserActionItems.REMOVE_DECLINED_USER.id,
      label: UserActionItems.REMOVE_DECLINED_USER.label,
      user,
    };
    const removeUserMenuItem = {
      id: UserActionItems.REMOVE_USER.id,
      label: UserActionItems.REMOVE_USER.label,
      user,
    };

    let menuItemsToRender: UserQMenuItemType[];

    switch (inviteStatus) {
      case InviteStatusMap.pending:
        menuItemsToRender = [...baseActions, cancelInviteMenuItem, resendInviteMenuItem];
        break;
      case InviteStatusMap.declined:
        menuItemsToRender = [...baseActions, removeDeclinedMenuItem, resendInviteMenuItem];
        break;
      case InviteStatusMap.accepted:
        menuItemsToRender = [...baseActions, removeUserMenuItem];
        break;
      default:
        menuItemsToRender = baseActions;
    }

    return {
      checkbox: (
        <QCheckbox
          data-cy={`${email}-checkbox`}
          aria-label="userlist_checkbox"
          isChecked={selectedUserIds.has(id)}
          onChange={handleCheckboxChange}
        />
      ),
      id,
      email: (
        <>
          <div>{email}</div>
          <div>{formQbadge(inviteStatus)}</div>
        </>
      ),
      inviteStatus,
      fullName: fullName || 'N/A',
      role: getUserRoleLabel(role),
      groups: (
        <>
          {groups.map((group: { name: string }) => (
            <Tag key={`${email}${group.name}`} label={group.name} clickHandler={groupFilterAction} />
          ))}
        </>
      ),
      access: (
        <>
          {access.map((value: string) => (
            <Tag key={`${email}${value}`} label={value} />
          ))}
        </>
      ),
      actions: (
        <QMenuButton
          buttonLabel="user actions"
          iconName="MoreVertical"
          itemSize="sm"
          items={menuItemsToRender}
          onItemClick={actionHandler as (item: QMenuItemType) => void}
          variant="icon"
        />
      ),
    };
  });
};

export const isBillingUser = (user: User) => user.access.includes(BILLING_ACCESS);
export const hasBillingPermissionV2 = (user: UserV2Type) => Boolean(user.medtechPermissions[CanAccessBilling]);
export const isInvitePendingV2 = (user: UserV2Type) => user.inviteStatus === InviteStatusMap.pending;

export const isValidFullName = (fullName: string) => {
  return Boolean(fullName) && fullName.length <= EMAIL_MAX_LENGTH;
};

export const sortGroupsByName = (groupA: Group, groupB: Group) =>
  groupA.name.toLowerCase().localeCompare(groupB.name.toLowerCase());
export const sortUsersByFullName = (userA: User, userB: User) =>
  userA.full_name.toLowerCase().localeCompare(userB.full_name.toLowerCase());
const userHasInviteStatus = ({ invite_status: inviteStatus }: User, statusToCheckFor: string) => {
  return inviteStatus === statusToCheckFor;
};

export const isUserInvitePending = (user: User) => userHasInviteStatus(user, InviteStatusMap.pending);
export const isUserInviteAccepted = (user: User) => userHasInviteStatus(user, InviteStatusMap.accepted);
export const isInviteAccepted = (inviteStatus: User['invite_status']) => inviteStatus === InviteStatusMap.accepted;
export const isUserQm = (user: User) => user.role === MedtechRolesMap.quality;
export const isUserChangingSelf = (currentUserId: number, targetUserId: number) => currentUserId === targetUserId;

export const getUserCompany = (user: MTBEUserType, companyId: number): MTBEUserCompanyType | undefined =>
  user.companies.find(({ id }) => id === companyId);

export const isCompanyAdmin = (usercompany: MTBEUserCompanyType | undefined): boolean => Boolean(usercompany?.isAdmin);

/**
 * @description Our `updateUserById` response is actually an enriched MTBEUser,
 * so this method is returning if an enriched user is an admin for a specified company.
 * Eventually, we can remove this method when the old Edit User modal that users v1 of
 * `updateUserById is deprecated
 */
export const isUserCompanyAdmin = (user: MTBEUserType, companyId: number): boolean => {
  const usercompany = getUserCompany(user, companyId);
  const isAdmin = isCompanyAdmin(usercompany);

  return isAdmin;
};

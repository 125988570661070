import {
  QSelectItem,
  QSpacer,
  QSpinner,
  QStack,
  QText,
  useToastProvider,
  useCurrentUser as useQCurrentUser,
} from '@qualio/ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { deleteUser, getCompanyById, getUsersByCompanyId, getUserAssociations, inviteUser } from '../../../api/um-api';
import { Group, InviteUserRequest, User, UserQMenuItemType } from '../../../types';
import { createErrorToast, createSuccessToast } from '../../../utils/toast.utils';
import { UserActionItems } from '../../../utils/umfe.enums';
import {
  isBillingUser,
  isInviteAccepted,
  isUserChangingSelf,
  isUserQm,
  sortUsersByFullName,
  sortGroupsByName,
} from '../../../utils/users.utils';
import {
  AddToGroupModal,
  InviteUserFormModal,
  RemoveUserGroupsModal,
  SetRoleModal,
  UserModal,
  RemoveUserModal,
} from '../../modals';
import { useUmAnalytics } from '../../../analytics';
import * as DisplayStrings from './__displayStrings__';
import { useFlagProvider } from '../../../utils/flagProvider';
import { UserList } from './UserList';
import { UserActionButtons } from './UserActionButtons';
import {
  MinimumRequiredAdminsErrorMessage,
  MinimumRequiredQualityUsersErrorMessage,
} from '../../../errors/__displayStrings__';
import redirectToLogin from '../../../utils/redirect';
import { ErrorCodes, getErrorMessageOrDefault } from '../../../errors/um-errors';
import { isAxiosError } from '../../../utils/validation/axios';
import { getGroups } from '../../../api/medtech-api';

type CompanyUserCount = {
  activeUsersCount: number;
  activeUsersLimit: number;
  totalUsersCount: number;
  totalUsersLimit: number;
};

const Users = () => {
  const { userId: currentUserId, companyId: currentCompanyId } = useQCurrentUser();
  const analytics = useUmAnalytics();
  const { showToast } = useToastProvider();
  const navigate = useNavigate();

  // Data from API
  const [rawUsers, setRawUsers] = useState<User[]>();
  const [users, setUsers] = useState<User[]>();
  const [userCounts, setUserCounts] = useState<CompanyUserCount>();
  const [groups, setGroups] = useState<Group[]>();

  // State selection
  const [clickedUser, setClickedUser] = useState<User>();
  const [selectedUserIds, setSelectedUserIds] = useState<Set<number>>(new Set());
  const [selectedGroups, setSelectedGroups] = useState<QSelectItem[]>([]);
  const areUsersSelected = selectedUserIds.size > 0;

  // Loading flags
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [isFetchUserAssociations, setFetchUserAssociations] = useState(false);
  const isLoading = !(rawUsers && users && userCounts && groups);

  // Modal controls
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isSetRoleModalOpen, setRoleModalOpen] = useState(false);
  const [isAddGroupModalOpen, setAddGroupModalOpen] = useState(false);
  const [isRemoveUserGroupsModalOpen, setRemoveUserGroupsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isRemoveUserWithEntities, setRemoveUserWithEntities] = useState<boolean>(false);

  const closeRoleModal = useCallback(() => setRoleModalOpen(false), [setRoleModalOpen]);
  const closeAddGroupModal = useCallback(() => setAddGroupModalOpen(false), [setAddGroupModalOpen]);
  const closeRemoveUserGroupsModal = useCallback(
    () => setRemoveUserGroupsModalOpen(false),
    [setRemoveUserGroupsModalOpen],
  );
  const closeInviteModal = useCallback(() => setInviteModalOpen(false), [setInviteModalOpen]);
  const closeEditUserModal = useCallback(() => setEditUserModalOpen(false), [setEditUserModalOpen]);
  const closeDeleteUserModal = useCallback(() => setDeleteModalOpen(false), [setDeleteModalOpen]);

  const showEditUser =
    useFlagProvider().isFlagEnabled('umQeSelfService') || useFlagProvider().isFlagEnabled('umSuppliersSelfService');

  const getCompanyUserCount = async (companyId: number) => {
    const { data } = await getCompanyById(companyId);
    const { activeUsersCount = 0, activeUsersLimit, totalUsersCount = 0, totalUsersLimit } = data;
    setUserCounts({
      activeUsersCount,
      activeUsersLimit,
      totalUsersCount,
      totalUsersLimit,
    });
  };

  /**
   * UseCallback here because this is passed to child components
   * so that children can update user state
   */
  const getRawUsers = async (companyId: number) => {
    const { data } = await getUsersByCompanyId(companyId);

    setRawUsers(data);
  };

  const getUsers = useCallback(async (companyId: number) => {
    getRawUsers(companyId);
    getCompanyUserCount(companyId);
  }, []);

  const fetchGroups = useCallback(
    async (companyId: number) => {
      const { data } = await getGroups(companyId);
      setGroups(data.sort(sortGroupsByName));
    },
    [getGroups, setGroups, sortGroupsByName],
  );

  // Get users
  useEffect(() => {
    getUsers(currentCompanyId);
  }, [currentCompanyId]);

  // Get groups for drop-down menu
  useEffect(() => {
    fetchGroups(currentCompanyId);
  }, [currentCompanyId]);

  useEffect(() => {
    if (isRemoveUserWithEntities && clickedUser) {
      navigate(`/admin/users/${clickedUser.id}/remove`);
    }
  }, [isRemoveUserWithEntities]);

  useEffect(() => {
    if (clickedUser && isFetchUserAssociations) {
      getUserAssociations(clickedUser.id, currentCompanyId).then((res) => {
        const { approver, issues, owner, reviewer, tasks, default_owner: defaultOwner } = res.data;
        if (
          (approver.documents.length ||
            approver.periodic_reviews.length ||
            owner.documents.length ||
            owner.change_requests.length ||
            issues.issues.length ||
            reviewer.documents.length ||
            tasks.tasks.length ||
            defaultOwner.event_templates.length) > 0
        ) {
          setRemoveUserWithEntities(true);
        } else {
          setDeleteModalOpen(true);
          setFetchUserAssociations(false);
        }
      });
    }
  }, [isFetchUserAssociations]);

  // Whenever raw users or filters are updated, we should update `users`
  useEffect(() => {
    if (rawUsers) {
      setUsers(
        rawUsers
          ?.filter((row) => {
            return selectedGroups.length === 0
              ? true
              : !!row?.groups?.find((group) =>
                  selectedGroups.some((sG) => sG.label.toUpperCase() === group?.name?.toUpperCase()),
                );
          })
          .sort(sortUsersByFullName),
      );
    }
  }, [selectedGroups, rawUsers]);

  const currentlySelectedUsers = useMemo(() => {
    return rawUsers ? rawUsers.filter((user) => selectedUserIds.has(user.id)) : [];
  }, [selectedUserIds, rawUsers]);

  const sendUserInvitation = async (companyId: number, user: User) => {
    const { email, role, isAdmin } = user;
    const isBilling = isBillingUser(user);
    const payload: InviteUserRequest = {
      email,
      isAdmin,
      isBilling,
      role,
    };

    try {
      await inviteUser(companyId, payload);
      await getUsers(companyId);
      showToast(createSuccessToast(DisplayStrings.ResendInvitationSuccessText));
      analytics.inviteUserSuccess(isAdmin, { role });
    } catch (err: unknown) {
      const status = isAxiosError(err) ? err?.response?.status : undefined;
      showToast(createErrorToast(DisplayStrings.ResendInvitationFailedText));
      analytics.inviteUserFailed(isAdmin, { role, status });
    }
  };

  const handleCancelUser = async (companyId: number, user: User) => {
    setDeleting(true);
    const { id, email } = user;
    const cancelInvitePayload = { email };

    try {
      await deleteUser(companyId, id, cancelInvitePayload);
      await getUsers(companyId);
      setDeleteModalOpen(false);
      setSelectedUserIds(new Set());
      showToast(createSuccessToast(DisplayStrings.CancelInvitationSuccessText));
      analytics.cancelUserSuccess();
    } catch (err: unknown) {
      const status = isAxiosError(err) ? err?.response?.status : undefined;
      const errorMessageMap = {
        [ErrorCodes.MINIMUM_ADMINS_REQUIRED]: MinimumRequiredAdminsErrorMessage,
      };

      const errorMessage = getErrorMessageOrDefault(err, errorMessageMap, DisplayStrings.CancelInvitationFailedText);

      showToast(createErrorToast(errorMessage));
      analytics.cancelUserFailed({ status, errorMessage });
    } finally {
      setDeleting(false);
    }
  };

  const cancelUserCallback = useCallback(
    () => handleCancelUser(currentCompanyId, clickedUser!),
    [currentCompanyId, clickedUser],
  );

  const handleDeleteUser = async (companyId: number, user: User) => {
    try {
      setDeleting(true);

      const { id } = user;
      const isQm = isUserQm(user);

      await deleteUser(companyId, id, { id, isQm });

      if (isUserChangingSelf(currentUserId, user.id)) {
        redirectToLogin();
      }

      await getUsers(companyId);
      setDeleteModalOpen(false);
      setSelectedUserIds(new Set());
      showToast(createSuccessToast(DisplayStrings.RemoveUserSuccessText));
      analytics.removeUserSuccess();
    } catch (err: unknown) {
      const status = isAxiosError(err) ? err?.response?.status : undefined;

      const errorMessageMap = {
        [ErrorCodes.MINIMUM_ADMINS_REQUIRED]: MinimumRequiredAdminsErrorMessage,
        [ErrorCodes.MINIMUM_QM_USER_REQUIRED]: MinimumRequiredQualityUsersErrorMessage,
      };

      const errorMessage = getErrorMessageOrDefault(err, errorMessageMap, DisplayStrings.RemoveUserFailedText);

      showToast(createErrorToast(errorMessage));
      analytics.removeUserFailed({ status, errorMessage });
    } finally {
      setDeleting(false);
    }
  };

  const deleteUserCallback = useCallback(
    () => handleDeleteUser(currentCompanyId, clickedUser!),
    [currentCompanyId, clickedUser],
  );

  const handleUserAction = useCallback(
    (actionItem: UserQMenuItemType): void => {
      const { id: actionItemId, user } = actionItem;
      setClickedUser(user);
      if (actionItemId === UserActionItems.EDIT_USER.id) {
        if (showEditUser) {
          navigate(`/admin/users/${user.id}`);
          return;
        }
        setEditUserModalOpen(true);
      }
      if (actionItemId === UserActionItems.CANCEL_INVITE.id) {
        setDeleteModalOpen(true);
      }
      if (actionItemId === UserActionItems.REMOVE_USER.id) {
        setFetchUserAssociations(true);
      }
      if (actionItemId === UserActionItems.REMOVE_DECLINED_USER.id) {
        setDeleteModalOpen(true);
      }
      if (actionItemId === UserActionItems.RESEND_INVITE.id) {
        sendUserInvitation(currentCompanyId, user);
      }
    },
    [navigate, setClickedUser, setEditUserModalOpen, setFetchUserAssociations, setDeleteModalOpen, sendUserInvitation],
  );

  const groupFilterAction = useCallback(
    (groupName?: string) => {
      if (!groupName) {
        setSelectedGroups([]);
        return;
      }

      const selectedGroupItem = groups?.find((group) => group.name.toLowerCase() === groupName.toLowerCase())!;

      const newSelectedGroups = selectedGroups.filter(
        (group) => group.label.toLowerCase() !== selectedGroupItem.name.toLowerCase(),
      );

      // This means the selected group was not previously in the selected groups array so we need to add it
      if (newSelectedGroups.length === selectedGroups.length) {
        newSelectedGroups.push({ value: selectedGroupItem.id.toString(), label: selectedGroupItem.name });
      }
      setSelectedGroups(newSelectedGroups);
    },
    [groups, selectedGroups],
  );

  const refetchUsers = useCallback(() => getUsers(currentCompanyId), [currentCompanyId]);

  return isLoading ? (
    <QSpinner size="sm" />
  ) : (
    <QStack direction="column">
      <QText aria-label="userCount-text" fontSize="sm">
        <DisplayStrings.UserCountMessage
          activeUsersCount={userCounts.activeUsersCount}
          activeUsersLimit={userCounts.activeUsersLimit}
          totalUsersCount={userCounts.totalUsersCount}
          totalUsersLimit={userCounts.totalUsersLimit}
        />
      </QText>
      <QSpacer p={1} />
      <UserActionButtons
        areUsersSelected={areUsersSelected}
        currentlySelectedUsers={currentlySelectedUsers}
        setRoleModalOpen={setRoleModalOpen}
        setAddGroupModalOpen={setAddGroupModalOpen}
        setRemoveUserGroupsModalOpen={setRemoveUserGroupsModalOpen}
        setInviteModalOpen={setInviteModalOpen}
      />
      <UserList
        users={users}
        selectedUserIds={selectedUserIds}
        groups={groups}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        setSelectedUserIds={setSelectedUserIds}
        handleUserAction={handleUserAction}
        filterByGroup={groupFilterAction}
      />
      {/* Modals */}
      {isSetRoleModalOpen && (
        <SetRoleModal
          closeModal={closeRoleModal}
          users={currentlySelectedUsers}
          companyId={currentCompanyId}
          getUsers={refetchUsers}
        />
      )}
      {isAddGroupModalOpen && (
        <AddToGroupModal
          companyId={currentCompanyId}
          closeModal={closeAddGroupModal}
          users={currentlySelectedUsers}
          groups={groups}
          getUsers={refetchUsers}
        />
      )}
      {isRemoveUserGroupsModalOpen && (
        <RemoveUserGroupsModal
          closeModal={closeRemoveUserGroupsModal}
          companyId={currentCompanyId}
          users={currentlySelectedUsers}
          getUsers={refetchUsers}
        />
      )}
      {isInviteModalOpen && (
        <InviteUserFormModal closeModal={closeInviteModal} companyId={currentCompanyId} getUsers={refetchUsers} />
      )}
      {isEditUserModalOpen && clickedUser && (
        <UserModal
          user={clickedUser}
          closeModal={closeEditUserModal}
          companyId={currentCompanyId}
          getUsers={refetchUsers}
        />
      )}
      {/* Confirm Delete Modal */}
      {isDeleteModalOpen && clickedUser && (
        <RemoveUserModal
          closeModal={closeDeleteUserModal}
          removeUser={isInviteAccepted(clickedUser.invite_status) ? deleteUserCallback : cancelUserCallback}
          isDeleting={isDeleting}
          user={clickedUser}
        />
      )}
    </QStack>
  );
};

export default Users;

import { DependencyList, EffectCallback, useEffect } from 'react';
import Noop from '../utils/noop';
import useIsFirstRender from './useIsFirstRender';

// React Hook that runs when dependencies are updated but not on first render
function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    return isFirst ? Noop() : effect();
  }, deps);
}

export default useUpdateEffect;

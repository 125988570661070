import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QSelectItem,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useMemo, useState } from 'react';
import { addUsersToGroup } from '../../api/um-api';
import { Group, ModalComponentProps, User } from '../../types';
import { formatGroupsToSelectOptions } from '../../utils/groups.utils';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import {
  AddedUsersToGroupErrorMessage,
  AddedUsersToGroupSuccessMessage,
  CancelButtonLabel,
  ModalHeader,
  SubmitButtonLabel,
} from './__displayStrings__/AddToGroupModal';

type AddToGroupModalProps = ModalComponentProps & {
  users: User[];
  groups: Group[];
  companyId: number;
  getUsers: () => Promise<void>;
};

export const AddToGroupModal = ({ companyId, closeModal, users, groups, getUsers }: AddToGroupModalProps) => {
  const { showToast } = useToastProvider();
  const [selectedGroup, setSelectedGroup] = useState<QSelectItem>();
  const [isLoading, setLoading] = useState(false);

  const options = useMemo(() => formatGroupsToSelectOptions(groups), [groups]);

  const handleSubmit = async () => {
    if (!selectedGroup) {
      return;
    }
    setLoading(true);

    const payload: { groups: number[]; users: number[] } = {
      groups: [+selectedGroup.value],
      users: users.map((user) => user.id),
    };

    try {
      await addUsersToGroup(companyId, payload);
      await getUsers();
      showToast(createSuccessToast(AddedUsersToGroupSuccessMessage(users.length)));
    } catch (err: any) {
      showToast(createErrorToast(err.response?.data?.message || AddedUsersToGroupErrorMessage));
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <QModal onClose={closeModal} size="md" isOpen>
      <QModalHeader>
        <QText data-cy="heading">{ModalHeader}</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>
        <QSelect
          aria-label="group"
          value={selectedGroup?.label}
          onChange={(e: any) => setSelectedGroup(e)}
          options={options}
        />
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-button" variant="outline" onClick={closeModal}>
          {CancelButtonLabel}
        </QButton>
        <QButton data-cy="add-button" isDisabled={!selectedGroup} isLoading={isLoading} onClick={handleSubmit}>
          {SubmitButtonLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

const basicRole = 'read_only';
const normalRole = 'normal';
const qualityRole = 'quality';

export const MedtechRoles = [basicRole, normalRole, qualityRole] as const;
export type MedtechRole = (typeof MedtechRoles)[number];

export const MedtechRolesMap = {
  basic: basicRole,
  normal: normalRole,
  quality: qualityRole,
} as const;

export const isMedtechRole = (role: string): role is MedtechRole => {
  return MedtechRoles.includes(role as MedtechRole);
};

import React, { ReactElement } from 'react';
import { QHeading, QText } from '@qualio/ui-components';
import { ReassignChangeRequestsMap, ReassignDocumentsMap } from '../../types';
import { filterUniqueOwners } from '../../utils';
import { OwnerStrings } from './__displayStrings__';
import { ReassignOwnerSummaryItemType } from '../types';

type PropsType = {
  items: ReassignDocumentsMap | ReassignChangeRequestsMap;
  type: ReassignOwnerSummaryItemType;
};

/**
 * This exists because the "Documents" and "Change Requests" section of the summary screen when removing
 * a user (the "owner" association) is slightly different then the rest in how it displays the reassign string
 */
const ReassignOwnerSummaryItem = ({ items, type }: PropsType): ReactElement => {
  const uniqueOwners = filterUniqueOwners(items);
  return (
    <QHeading size="sm" fontSize="md" fontWeight="bold">
      {OwnerStrings.getTitle(type)}
      {uniqueOwners.map(([owner, count]) => {
        const [prefix, countString, suffix] = OwnerStrings.reassignStringBuilder(count, owner, type);

        return (
          <QText key={owner} fontSize="xs" fontWeight="light">
            {prefix} <strong>{countString}</strong> {suffix}
          </QText>
        );
      })}
    </QHeading>
  );
};

export default ReassignOwnerSummaryItem;

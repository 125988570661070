import { ObjectWithNewOwner, ReassignedEntity } from './types';

// Used to verify that all of the Entities in an array of entities (could be docs or items) have a new owner
export const AllEntitiesReassigned = (entityList: ObjectWithNewOwner[]): entityList is ReassignedEntity[] => {
  return entityList.every((qualioEntity) => Boolean(qualioEntity.newOwner));
};

type EntitiesMap = Record<string, ObjectWithNewOwner>;
type ReassignedEntitiesMap = Record<string, ReassignedEntity>;

// Used to verify that all of the Entities in a map (could be docs or items) have a new owner
export const AllEntitiesInMapReassigned = (entityMap: EntitiesMap): entityMap is ReassignedEntitiesMap => {
  return AllEntitiesReassigned(Object.values(entityMap));
};

/**
 * Used to iterate over a map of Entities (docs or items) and produce an array of tuples that contain
 * the name of the new owner of the entity, and the number of new entities they now own
 */
export const filterUniqueOwners = (entityMap: EntitiesMap): [string, number][] => {
  const overrideMap = Array.from(Object.values(entityMap)).reduce((aggregate, entity) => {
    const { newOwner } = entity;
    const name = newOwner ? newOwner.name : null;

    if (!name) {
      return aggregate;
    }

    const currentCount = aggregate[name] ? aggregate[name] : 0;

    return {
      ...aggregate,
      [name]: currentCount + 1,
    };
  }, {} as Record<string, number>);

  return Object.keys(overrideMap).map((name) => {
    const docCount = overrideMap[name];

    return [name, docCount];
  });
};

type ObjectWithId = {
  id: number;
  [key: string | number | symbol]: unknown;
};

export function addNewOwnerToItems<T extends ObjectWithId>(aggregate: Record<number, T>, item: T): Record<number, T> {
  const { id } = item;

  return {
    ...aggregate,
    [id]: {
      ...item,
      newOwner: null,
    },
  };
}

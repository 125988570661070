import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { QDivider, QHeading, QText, useToastProvider, useCurrentUser as useQCurrentUser } from '@qualio/ui-components';
import { useParams, useNavigate } from 'react-router-dom';
import ReassignQualioEntities from '../../../components/ReassignQualioEntities';
import * as DisplayStrings from './__displayStrings__';
import { RemoveUserContainer } from '../../../styles/Users.styles';
import { User, UserAssociations } from '../../../types/user.types';
import { PotentialNewOwners, ReassignResultObject } from '../../../components/ReassignQualioEntities/types';
import { deleteUserWithDocuments, getGroupMap, getItemsThatNeedReassigned, getUsers } from './api';
import { createErrorToast, createSuccessToast } from '../../../utils/toast.utils';
import { GroupMap } from '../../../types';
import Loading from '../../../components/Loading';
import { ErrorCodes, getErrorMessageOrDefault } from '../../../errors/um-errors';
import {
  MinimumRequiredAdminsErrorMessage,
  MinimumRequiredQualityUsersErrorMessage,
} from '../../../errors/__displayStrings__';

/**
 * This component is used when removing a user who has "entities" associated to
 * them that need reassigned. These "Entities" can include "doc" entities,
 * i.e. Documents that the user being removed is the "owner" of, and ones where
 * the user being removed is listed as a "reviewer" or "approver".
 * These "entities" can also be of "items", i.e. "issues" or "tasks" that belong
 * to the user being removed
 */
const RemoveUserAssociations = (): ReactElement => {
  const currentUser = useQCurrentUser();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { showToast } = useToastProvider();
  const idOfUserToBeRemoved = Number(params.id);
  const { companyId } = currentUser;

  const [potentialOwners, setPotentialOwners] = useState<PotentialNewOwners[]>();
  const [userToBeRemoved, setUserToBeRemoved] = useState<User | undefined>();
  const [itemsToReassign, setItemsToReassign] = useState<UserAssociations>();
  const [groupMap, setGroupMap] = useState<GroupMap>();

  const handleCancel = useCallback(() => {
    navigate(`/admin/users`, { replace: true });
  }, []);

  const handleSubmit = async (updateData: ReassignResultObject) => {
    try {
      await deleteUserWithDocuments(companyId, idOfUserToBeRemoved, updateData);
      navigate('/admin/users', { replace: true });
      showToast(createSuccessToast(DisplayStrings.SuccessMessage));
    } catch (err: unknown) {
      const errorMessageMap = {
        [ErrorCodes.MINIMUM_ADMINS_REQUIRED]: MinimumRequiredAdminsErrorMessage,
        [ErrorCodes.MINIMUM_QM_USER_REQUIRED]: MinimumRequiredQualityUsersErrorMessage,
      };
      const errorMessage = getErrorMessageOrDefault(err, errorMessageMap, DisplayStrings.DefaultErrorMessage);
      showToast(createErrorToast(errorMessage));
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const [{ potentialNewOwners, userToBeRemoved: removingUser }, associations, groups] = await Promise.all([
        getUsers(companyId, idOfUserToBeRemoved),
        getItemsThatNeedReassigned(idOfUserToBeRemoved, companyId),
        getGroupMap(companyId),
      ]);

      setUserToBeRemoved(removingUser);
      setPotentialOwners(potentialNewOwners);
      setItemsToReassign(associations);
      setGroupMap(groups);
    };

    initialize();
  }, []);

  // If either Users or itemsToReassign are undefined, component is still loading
  if (!potentialOwners || !itemsToReassign || !groupMap) {
    return <Loading />;
  }

  return (
    <RemoveUserContainer>
      <QHeading data-cy="heading">{DisplayStrings.Header(userToBeRemoved?.full_name)}</QHeading>
      <QText fontSize="xs" weight="regular" color="gray.800">
        {DisplayStrings.Description}
      </QText>
      <ReassignQualioEntities
        users={potentialOwners}
        itemsToReassign={itemsToReassign}
        groupMap={groupMap}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
      <QDivider />
    </RemoveUserContainer>
  );
};

export default RemoveUserAssociations;

import { AssociationStatus, FeatureEnum, UsergroupsList } from './umfe.enums';

export const isFeature = (featureElements: string): boolean => featureElements in FeatureEnum;

export const getFeatureInfo = (featureId: string): string =>
  isFeature(featureId) ? FeatureEnum[featureId] : featureId;
export const getFeatureAccessName = (featureAccess: string): string => FeatureEnum[featureAccess] || featureAccess;
/**
 * @description: Take in a role and return the user role label
 * @param role
 * @returns
 */
export const getUserRoleLabel = (role: string) => {
  const userRole = Object.values(UsergroupsList).find(({ value }) => value === role);
  if (userRole) {
    return userRole.label;
  }
  throw new Error(`No label found for role: ${role}`);
};

export const getAssociationStatus = (status: string) => {
  const associationStatus = Object.values(AssociationStatus).find(({ id }) => id === status);
  if (associationStatus) {
    return associationStatus.label;
  }
  return status;
};

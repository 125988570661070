import React, { ReactElement } from 'react';
import { QHeading, QText } from '@qualio/ui-components';
import { ReassignItemsMap, ReassignDocumentsMap } from '../../types';
import { filterUniqueOwners } from '../../utils';
import { BasicSummaryCategories } from './types';
import { StandardStrings } from './__displayStrings__';

type StandardSummaryItemProps = {
  items: ReassignDocumentsMap | ReassignItemsMap;
  summaryCategory: BasicSummaryCategories;
};

/**
 * This component renders the summary items for each of the basic categories,
 * i.e. "3 issue actions reassigned to Some User"
 */
const StandardSummaryItem = ({ items, summaryCategory }: StandardSummaryItemProps): ReactElement => {
  const uniqueOwners = filterUniqueOwners(items);
  const { title, reassignStringBuilder } = StandardStrings[summaryCategory];

  return (
    <QHeading size="sm" fontSize="md" fontWeight="bold">
      {title}
      {uniqueOwners.map(([owner, count]) => {
        const [prefix, suffix] = reassignStringBuilder(count, owner);

        return (
          <QText key={owner} fontSize="xs" fontWeight="light">
            <strong>{prefix}</strong> {suffix}
          </QText>
        );
      })}
    </QHeading>
  );
};

export default StandardSummaryItem;

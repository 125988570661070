import { medtechClient } from '../services/medtechAxiosClient';
import {
  ApiListResponse,
  ApiObjectResponse,
  CreateGroupPayload,
  Group,
  MTBEUpdateUserDTO,
  UpdateGroupPayload,
  UpdateUserByIdRequestBody,
} from '../types';
import { FeaturePayload, FeaturesResponse } from '../types/features.types';
import { MedtechRole } from '../types/medtechRole.types';
import { validateIsFormerUserResponseData } from '../utils/validation/medtech-api';
import { MTBEUserType, validateMTBEUser } from '../utils/validation/mtbe-user';
import {
  MedtechPermissionByRole,
  MedtechPermissionsByRoles,
  MedtechPermissionsByRolesMap,
  validateMedtechPermissions,
} from '../utils/validation/permissions';
import { UserV2Type } from '../utils/validation/user';
import { ManageableUsergroupPermission, UsergroupPermissionsByRoleResponse } from '../views/admin/Permissions/types';

/**
 * Gets medtech permissions based on the domain from MTBE
 * Used internally by other methods to avoid having components pass in specific domains
 */
const getMedtechDefaultPermissions = async <T>(companyId: number) => {
  const { data: permissions } = await medtechClient.get<T>(`/v2/${companyId}/permissions`);
  return permissions;
};

/**
 * Returns map of user roles to default QE permissions from MTBE
 */
export const getMedtechPermissions = async (companyId: number): Promise<MedtechPermissionsByRolesMap> => {
  const defaultPermissions = await getMedtechDefaultPermissions<MedtechPermissionsByRoles>(companyId);
  const defaultPermissionsValidated = validateMedtechPermissions(defaultPermissions);
  const permissionsMap = defaultPermissionsValidated.reduce(
    (acc: MedtechPermissionsByRolesMap, permissionsByRole: MedtechPermissionByRole) => {
      const { userGroup, permissions } = permissionsByRole;
      return {
        ...acc,
        [userGroup]: permissions.map(({ permission_id }) => permission_id),
      };
    },
    {} as MedtechPermissionsByRolesMap,
  );
  return permissionsMap;
};

export const isFormerUserCompany = async (companyId: number, email: string): Promise<boolean> => {
  const response = await medtechClient.get<{ former: number }>(`companies/${companyId}/former`, {
    params: {
      email,
    },
  });
  const validatedData = validateIsFormerUserResponseData(response.data);

  return Boolean(validatedData.former);
};

export const deleteGroup = (groupId: number): Promise<unknown> => {
  return medtechClient.delete(`/groups/${groupId}`);
};

export const createGroup = (payload: CreateGroupPayload): Promise<unknown> => {
  return medtechClient.post(`/groups`, payload);
};

export const patchGroup = (groupId: number, payload: UpdateGroupPayload): Promise<ApiObjectResponse<Group>> => {
  return medtechClient.patch(`/groups/${groupId}`, payload);
};

export const getGroups = (companyId: number): Promise<ApiListResponse<Group>> => {
  return medtechClient.get(`/${companyId}/groups`);
};

export const getCompanyAdminSettings = (companyId: number) => {
  return medtechClient.get<{ ai_enabled: boolean; allow_ghost: boolean }>(`/company/${companyId}/admin_settings`);
};

export const patchCompanyAdminSettings = (
  companyId: number,
  settings: { ai_enabled?: boolean; allow_ghost?: boolean },
): Promise<unknown> => {
  return medtechClient.patch(`/company/${companyId}/admin_settings`, settings);
};

export const getCompanyFeatures = (companyId: number): Promise<FeaturesResponse> => {
  return medtechClient.get(`/company/${companyId}/features`);
};

export const patchCompanyFeatures = (companyId: number, payload: FeaturePayload): Promise<unknown> => {
  return medtechClient.patch(`/company/${companyId}/features`, payload);
};

export const fetchUsergroupPermissions = (companyId: number) => {
  return medtechClient
    .get<UsergroupPermissionsByRoleResponse>(`/v2/${companyId}/usergroup_permissions`)
    .then(({ data }) => data);
};

export const updateUsergroupPermission = async (
  companyId: number,
  usergroup: MedtechRole,
  permission: ManageableUsergroupPermission,
  enabled: boolean,
) => {
  await medtechClient.put<void>(`/v2/${companyId}/usergroup_permissions/${usergroup}`, {
    enabled,
    permission,
  });
};

export const updateUser = async (
  companyId: number,
  userId: number,
  payload: UpdateUserByIdRequestBody,
): Promise<MTBEUserType> => {
  const medtechPayload = { ...payload, company_id: companyId };
  const { data } = await medtechClient.patch(`/users/${userId}`, medtechPayload);

  return validateMTBEUser(data);
};

const buildMTBEPermissions = (medtechPermissions: any): MTBEUpdateUserDTO['permissions'] => {
  return Object.keys(medtechPermissions).map((permission) => ({
    [permission]: medtechPermissions[permission],
  }));
};

export const updateUserV2 = async (companyId: number, user: UserV2Type): Promise<MTBEUserType> => {
  const medtechPayload: MTBEUpdateUserDTO = {
    company_id: companyId,
    role: user.role,
    permissions: buildMTBEPermissions(user.medtechPermissions),
    is_admin: user.isAdmin,
  };
  // Only add full name and email to request payload if not a pending user
  if (user.inviteStatus !== 'pending') {
    medtechPayload.full_name = user.fullName;
    medtechPayload.email = user.email;
  }
  const { data } = await medtechClient.patch(`/users/${user.id}`, medtechPayload);
  return validateMTBEUser(data);
};

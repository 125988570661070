import React, { ReactElement } from 'react';

export const DeleteUserButtonLabel = 'Yes, remove user';
export const DeleteUserTitle = 'Remove user';

type DeleteUserMessageProps = {
  name: string;
};
/**
 * @description Message for deleting a user from the company
 */
export const DeleteUserMessage = ({ name }: DeleteUserMessageProps): ReactElement => {
  return (
    <>
      Are you sure you want to <strong>remove</strong> user {`"${name}"`}?
    </>
  );
};

import { environment } from '../environments/environment';
import { axiosClient } from '../services';
import {
  AddUsertoGroupPayload,
  ApiListResponse,
  ApiObjectResponse,
  CancelUserInviteRequest,
  CompanyInfo,
  DeleteUserByIdRequestBody,
  Feature,
  FeatureFlagUpdateResponse,
  Group,
  InviteUserRequest,
  RemoveGroupFromUserPayload,
  UpdateManyByIdRequestBody,
  UpdateUserByIdRequestBody,
  User,
  UserAssociations,
  UserGroupPermissions,
} from '../types';
import { MTBEUserType, validateMTBEUser } from '../utils/validation/mtbe-user';
import { UserV2Type, validateUserV2 } from '../utils/validation/user';

export const inviteUser = (
  companyId: number,
  inviteUserRequest: InviteUserRequest,
): Promise<ApiObjectResponse<User>> => {
  return axiosClient.post(`${environment.umEndpoint}/company/${companyId}/users`, inviteUserRequest);
};

export const getUsersByCompanyId = (companyId: number): Promise<ApiListResponse<User>> => {
  return axiosClient.get(`${environment.umEndpoint}/company/${companyId}/users`);
};

export const getCompanyFeatures = (companyId: number): Promise<ApiListResponse<Feature>> => {
  return axiosClient.get(`${environment.umEndpoint}/company/${companyId}/features`);
};

export const getUserGroupPermissions = (companyId: number): Promise<ApiListResponse<UserGroupPermissions>> => {
  return axiosClient.get(`${environment.umEndpoint}/company/${companyId}/groups/permissions`, {
    params: {
      enabled: true,
    },
  });
};

export const updateCompanyFeatureFlagStatus = (
  companyId: number,
  companyFeatureId: string,
  payload: { isActive: boolean; featureId: string },
): Promise<ApiObjectResponse<FeatureFlagUpdateResponse>> => {
  return axiosClient.put(`${environment.umEndpoint}/company/${companyId}/features/${companyFeatureId || 0}`, payload);
};

export const updateUserById = async (
  companyId: number,
  userId: number,
  payload: UpdateUserByIdRequestBody,
): Promise<MTBEUserType> => {
  const { data } = await axiosClient.patch(`${environment.umEndpoint}/company/${companyId}/users/${userId}`, payload);

  const user = validateMTBEUser(data);
  return user;
};

export const updateUsers = (companyId: number, payload: UpdateManyByIdRequestBody): Promise<ApiListResponse<User>> => {
  return axiosClient.patch(`${environment.umEndpoint}/company/${companyId}/users`, payload);
};

export const addUsersToGroup = (
  companyId: number,
  payload: AddUsertoGroupPayload,
): Promise<ApiObjectResponse<Group>> => {
  return axiosClient.post(`${environment.umEndpoint}/company/${companyId}/users/group`, payload);
};

export const removeUsersfromGroup = (companyId: number, data: RemoveGroupFromUserPayload): Promise<void> => {
  return axiosClient.delete(`${environment.umEndpoint}/company/${companyId}/users/group`, { data });
};

export const deleteUser = (
  companyId: number,
  userId: number,
  data: CancelUserInviteRequest | DeleteUserByIdRequestBody,
) => {
  return axiosClient.delete(`${environment.umEndpoint}/company/${companyId}/users/${userId}`, { data });
};

export const getCompanyById = (companyId: number): Promise<ApiObjectResponse<CompanyInfo>> => {
  return axiosClient.get(`${environment.umEndpoint}/company/${companyId}?usercount=true`);
};

/**
 * New method to get company by id, but oee that parses the response and just returns
 * the company object instead of the raw response.  Should add zod schema validation
 * but need to verify schema first
 */
export const getCompanyByIdV2 = async (companyId: number): Promise<CompanyInfo> => {
  const { data: company } = await getCompanyById(companyId);

  return company;
};

export const getUserAssociations = (
  userId: number,
  companyId: number,
): Promise<ApiObjectResponse<UserAssociations>> => {
  return axiosClient.get(`${environment.mtbeEndpoint}/v2/${companyId}/users/${userId}/associations`);
};

// Get a user by id for the given company
export const getUserById = async (companyId: number, userId: number): Promise<UserV2Type> => {
  const { data: rawUser } = await axiosClient.get(
    `${environment.umEndpoint}/v2/companies/${companyId}/users/${userId}`,
  );
  const user = validateUserV2(rawUser);

  return user;
};

// Update a user by id for the given company, using new v2 Endpoint
export const updateUserByIdV2 = async (companyId: number, user: UserV2Type): Promise<UserV2Type> => {
  const { data: rawUser } = await axiosClient.put(
    `${environment.umEndpoint}/v2/companies/${companyId}/users/${user.id}`,
    user,
  );
  const updatedUser = validateUserV2(rawUser);

  return updatedUser;
};

import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QSelectItem,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useMemo, useState } from 'react';
import { removeUsersfromGroup } from '../../api/um-api';
import { ConfirmationModal } from '../../containers/confirmation-modal';
import { ErrorCodes, getUMErrorCode } from '../../errors/um-errors';
import { DocumentsHiddenFromAuthorsErrorMessage } from '../../errors/__displayStrings__';
import { ModalComponentProps, User } from '../../types';
import { createErrorToast, createSuccessToast, createWarningToast } from '../../utils/toast.utils';
import {
  CancelBtnLabel,
  ConfirmationModalTitle,
  ConfirmButtonLabel,
  ErrorRemovingUsersFromGroupsMessage,
  RemoveBtnLabel,
  RemoveUserGroupModalHeader,
  SuccessMessage,
  UsersLoseDocumentVisibilityMessage,
} from './__displayStrings__/RemoveUserGroupsModal';

type UserLosingDocumentVisiblity = {
  id: number;
  full_name: string;
};

type RemoveUserGroupsModalProps = ModalComponentProps & {
  users: User[];
  getUsers: () => Promise<void>;
  companyId: number;
};

export const RemoveUserGroupsModal = ({ closeModal, companyId, users, getUsers }: RemoveUserGroupsModalProps) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<QSelectItem>();
  const [isRemoving, setRemoving] = useState<boolean>(false);
  const [endangeredUsers, setEndangeredUsers] = useState<UserLosingDocumentVisiblity[]>([]);
  const { showToast } = useToastProvider();

  const handleSubmit = (isForce: boolean) => {
    setRemoving(true);
    const payload: { groups: number[]; users: number[]; isForce: boolean } = {
      groups: [+group!.value],
      users: users.map((user) => user.id),
      isForce,
    };

    removeUsersfromGroup(companyId, payload)
      .then(async () => {
        await getUsers();
        setRemoving(false);
        showToast(createSuccessToast(SuccessMessage(users.length)));
        closeModal();
      })
      .catch((err) => {
        setRemoving(false);
        if (getUMErrorCode(err) === ErrorCodes.DOCUMENTS_HIDDEN_FROM_AUTHORS) {
          setEndangeredUsers(err.response.data.data.users);
          setConfirmationModalOpen(true);
          showToast(createWarningToast(DocumentsHiddenFromAuthorsErrorMessage));
        } else {
          showToast(createErrorToast(ErrorRemovingUsersFromGroupsMessage));
        }
      });
  };

  const options = useMemo(() => {
    const uniqueGroups = new Map<number, QSelectItem>();
    const groups = users.flatMap((user) => user.groups);
    groups.forEach(({ id, name }) => {
      if (!uniqueGroups.has(id)) {
        uniqueGroups.set(id, {
          label: name.toUpperCase(),
          value: id.toString(),
        });
      }
    });
    return Array.from(uniqueGroups.values());
  }, [users]);

  return (
    <QModal onClose={closeModal} size="md" isOpen>
      <QModalHeader>
        <QText data-cy="heading">{RemoveUserGroupModalHeader}</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>
        <QSelect
          aria-label="group"
          value={group?.label}
          onChange={(value) => value && setGroup(value)}
          options={options}
        />
        {isConfirmationModalOpen && (
          <ConfirmationModal
            closeModal={() => setConfirmationModalOpen(false)}
            title={ConfirmationModalTitle}
            buttonProps={{
              type: 'button',
              label: ConfirmButtonLabel,
              isDestructive: false,
              onClick: () => {
                handleSubmit(true);
              },
              isLoading: isRemoving,
            }}
          >
            {UsersLoseDocumentVisibilityMessage(endangeredUsers.map((user) => user.full_name))}
          </ConfirmationModal>
        )}
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-button" variant="outline" onClick={closeModal}>
          {CancelBtnLabel}
        </QButton>
        <QButton
          data-cy="remove-button"
          isDestructive
          isDisabled={!group}
          isLoading={isRemoving}
          onClick={() => handleSubmit(false)}
        >
          {RemoveBtnLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

import React, { ReactElement } from 'react';
import createCommaSeparatedListMessage from '../../../../utils/createCommaSeparatedList';

export const Confirm = 'Confirm';
export const Title = 'Add user to group';
export const WarningMessage =
  'If you proceed with removal, the following users will be added to the following groups required to access documents with private tags:';

type UserAddedToGroupsMessageProps = {
  userName: string;
  groupNames: string[];
};

/**
 * This will render the string:
 * <strong>John Doe</strong> will be added to the groups:
 *  <strong>Group 1</strong>, <strong>Group2</strong>, and <strong>Group3</strong>
 */
export const UserAddedToGroupsMessage = ({ userName, groupNames }: UserAddedToGroupsMessageProps): ReactElement => {
  const messagePrefix = (
    <span>
      <strong>{userName}</strong> will be added to the groups:{' '}
    </span>
  );
  return <span>{createCommaSeparatedListMessage(groupNames, { bold: true, messagePrefix })}</span>;
};

import React from 'react';
import { Field, FieldProps } from 'formik';
import { QFormControl, QCheckbox } from '@qualio/ui-components';

type CheckBoxProps = {
  isDisabled?: boolean;
  name: string;
  label: string;
  text: string;
  'data-cy': string;
};

// Wraps the Formik Field component with the Qualio Checkbox component
const CheckBox = ({ isDisabled = false, name, label, text, ...rest }: CheckBoxProps) => {
  return (
    <QFormControl size="sm">
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const { value, name: fieldName } = field;
          const { setFieldValue } = form;

          return (
            <QCheckbox
              {...rest}
              aria-label={label}
              isDisabled={isDisabled}
              isChecked={value}
              onChange={(event) => setFieldValue(fieldName, event.target.checked)}
            >
              {text}
            </QCheckbox>
          );
        }}
      </Field>
    </QFormControl>
  );
};

export default CheckBox;

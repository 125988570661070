/* eslint-disable react/jsx-props-no-spreading */
import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalButtonProps,
  QModalHeader,
  QText,
} from '@qualio/ui-components';
import React from 'react';
import { ModalComponentProps } from '../types';
import { CancelBtnLabel } from '../__displayStrings__/buttonsLabels';

type ConfirmationModalProps = ModalComponentProps & {
  title?: string;
  buttonProps: QModalButtonProps;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
  cancelLabel?: string;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  closeModal,
  title,
  buttonProps,
  size,
  cancelLabel = CancelBtnLabel,
  children,
}) => {
  return (
    <QModal onClose={closeModal} size={size} isOpen>
      <QModalHeader>
        <QText>{title}</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>{children}</QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={closeModal}>
          {cancelLabel}
        </QButton>
        <QButton {...buttonProps}>{buttonProps.label}</QButton>
      </QModalActions>
    </QModal>
  );
};

ConfirmationModal.defaultProps = {
  title: 'Confirm',
  size: 'md',
};

import { Environment } from '../types';

export const environment: Environment = {
  isProduction: false,
  mtbeEndpoint: 'https://api.staging.beta.qualio.com',
  authApiEndpoint: 'https://auth-exchange.staging.qualio.com',
  name: 'staging',
  loginUrl: `https://${window.location.host}/`,
  authSuccessRefreshIntervalMillis: 60000,
  umEndpoint: 'https://api.usermanagement.staging.beta.qualio.com',
  mtfeEndpoint: `https://${window.location.host}`,
  ldClientSideId: '61e6a31060139c1610d1516b',
};

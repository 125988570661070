import React, { ReactElement } from 'react';
import { QHeading, QText } from '@qualio/ui-components';
import { ReassignEventTemplatesMap } from '../../types';
import { filterUniqueOwners } from '../../utils';
import { EventTemplateDefaultOwnerStrings } from './__displayStrings__';

type PropsType = {
  items: ReassignEventTemplatesMap;
};

/**
 * This exists because the Event Template reassign summary is slightly different then the rest in how
 * it displays the reassign string
 */
const ReassignEventTemplateSummaryItem = ({ items }: PropsType): ReactElement => {
  const uniqueOwners = filterUniqueOwners(items);
  return (
    <QHeading size="sm" fontSize="md" fontWeight="bold">
      {EventTemplateDefaultOwnerStrings.title}
      {uniqueOwners.map(([owner, count]) => {
        const [prefix, countString, suffix] = EventTemplateDefaultOwnerStrings.reassignStringBuilder(count, owner);

        return (
          <QText key={owner} fontSize="xs" fontWeight="light">
            {prefix} <strong>{countString}</strong> {suffix}
          </QText>
        );
      })}
    </QHeading>
  );
};

export default ReassignEventTemplateSummaryItem;

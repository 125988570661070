import { deprecated } from '@qualio/ui-components';
import React from 'react';
import { z } from 'zod';
import { AccessTable } from '../../../components/Feature/AccessTable';
import { UserGroupSortOrder } from '../../../utils/umfe.enums';
import { MedtechPermissionsByRolesMap, ManageablePermissionsEnumSchema } from '../../../utils/validation/permissions';
import { PermissionsLabels } from '../../../__displayStrings__/displayStrings';
import { userRoleLabels } from '../../../__displayStrings__/qualioDisplayStrings';
import { NoRolePermissions } from './__displayStrings__';

type QualityEventsPermissionsTableProps = {
  qualityEventsDefaultPermissions: MedtechPermissionsByRolesMap;
};

const transformDefaultToTableData = (
  role: keyof MedtechPermissionsByRolesMap,
  currentRolePermissions: z.infer<typeof ManageablePermissionsEnumSchema>[],
) => {
  const accessString =
    currentRolePermissions.length === 0
      ? NoRolePermissions
      : currentRolePermissions.map((currentRolePermission) => PermissionsLabels[currentRolePermission]).join(', ');
  return {
    name: userRoleLabels[role],
    access: accessString,
    sort: UserGroupSortOrder[role],
  };
};

export const QualityEventsPermissionsTable = ({
  qualityEventsDefaultPermissions,
}: QualityEventsPermissionsTableProps): JSX.Element => {
  const keys = Object.keys(qualityEventsDefaultPermissions) as (keyof MedtechPermissionsByRolesMap)[];
  const tableData = keys
    .map((key: keyof MedtechPermissionsByRolesMap) =>
      transformDefaultToTableData(key, qualityEventsDefaultPermissions[key]),
    )
    .sort((a, b) => a.sort - b.sort);
  const tableColumns: deprecated.QDataColumn[] = [
    {
      Header: 'Role',
      accessor: 'name',
      width: '20%',
    },
    {
      Header: 'Default permissions',
      accessor: 'access',
      width: '80%',
    },
  ];
  return <AccessTable columns={tableColumns} rows={tableData} />;
};

import React from 'react';
import { QAlert, QButton, QButtonGroup, QDivider, QHeading, QStack, useCurrentUser } from '@qualio/ui-components';
import APICapabilitiesPermissions from '../../../components/FormComponents/APICapabilitiesPermissions';
import DesignControlsPermissions from '../../../components/FormComponents/DesignControlsPermissions';
import QualityEventsPermissions from '../../../components/FormComponents/QualityEventsPermissions';
import SuppliersPermissions from '../../../components/FormComponents/SuppliersPermissions';
import {
  PermissionAPICapabilitiesDescriptionText,
  PermissionDesignControlsDescriptionText,
  PermissionsEventsDescriptionText,
  PermissionsSuppliersDescriptionText,
  QEPermissionsWarningText,
} from './__displayStrings__';
import { PermissionWrapper } from './components/PermissionWrapper';

type PermissionsSectionProps = {
  isDisabled: boolean;
  isLoading: boolean;
  resetPermissions: () => void;
  onCancel: () => void;
};

export const PermissionsSection: React.VFC<PermissionsSectionProps> = ({
  isLoading,
  isDisabled,
  resetPermissions,
  onCancel,
}) => {
  const { capabilities } = useCurrentUser();

  const anyRelevantCapabilityEnabled =
    capabilities === null
      ? false
      : Object.keys(capabilities)
          .filter((capability) => !['sso', 'premium_integrations'].includes(capability))
          .some((capability) => capabilities[capability as keyof typeof capabilities]);

  return (
    <QStack direction={['column']} spacing={6}>
      {anyRelevantCapabilityEnabled && (
        <>
          <QStack spacing={4}>
            <QHeading fontWeight="bold" size="md" mt={2}>
              Permissions
            </QHeading>
            <PermissionWrapper
              shouldRender={capabilities?.events ?? false}
              header="Events"
              description={PermissionsEventsDescriptionText}
            >
              <>
                <QAlert description={QEPermissionsWarningText} status="info" />
                <QualityEventsPermissions />
              </>
            </PermissionWrapper>
            <PermissionWrapper
              shouldRender={capabilities?.suppliers ?? false}
              header="Suppliers"
              description={PermissionsSuppliersDescriptionText}
            >
              <SuppliersPermissions />
            </PermissionWrapper>
            <PermissionWrapper
              shouldRender={capabilities?.design_controls ?? false}
              header="Design Controls"
              description={PermissionDesignControlsDescriptionText}
            >
              <DesignControlsPermissions />
            </PermissionWrapper>
            <PermissionWrapper
              shouldRender={capabilities?.api ?? false}
              header="API Capabilities"
              description={PermissionAPICapabilitiesDescriptionText}
            >
              <APICapabilitiesPermissions />
            </PermissionWrapper>
            <QButton data-cy="reset-button" variant="outline" size="sm" onClick={resetPermissions}>
              Reset to default permissions
            </QButton>
          </QStack>
          <QDivider />
        </>
      )}
      <QButtonGroup>
        <QButton data-cy="cancel-button" variant="outline" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton data-cy="save-changes" isLoading={isLoading} isDisabled={isDisabled} size="sm" type="submit">
          Save changes
        </QButton>
      </QButtonGroup>
    </QStack>
  );
};

import { QHeading, QText } from '@qualio/ui-components';
import React from 'react';

type PermissionWrapperProps = {
  shouldRender: boolean;
  header: string;
  description: string;
};

export const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  shouldRender,
  header,
  description,
  children,
}) => {
  return shouldRender ? (
    <>
      <QHeading fontWeight="bold" size="sm">
        {header}
      </QHeading>
      <QText color="gray.500">{description}</QText>
      {children}
    </>
  ) : null;
};

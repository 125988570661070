import React, { ReactElement, useState } from 'react';
import ResetUserPermissionsModal from '../../../components/ResetUserPermissionsModal';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { MedtechRole } from '../../../types/medtechRole.types';

type RoleWatcherProps = {
  resetPermissions: () => void;
  role: MedtechRole;
};

const RoleWatcher = ({ resetPermissions, role }: RoleWatcherProps): null | ReactElement => {
  const [modalIsVisible, showModal] = useState(false);

  useUpdateEffect(() => {
    showModal(true);
  }, [role]);

  if (!modalIsVisible) {
    return null;
  }

  return <ResetUserPermissionsModal resetPermissions={resetPermissions} closeModal={() => showModal(false)} />;
};

export default RoleWatcher;

import * as R from 'ramda';
import { ManageablePermissionsEnumSchema } from './validation/permissions';
import { UserV2Type } from './validation/user';

const filterOnlyQEPermissions = (medtechPermissions: UserV2Type['medtechPermissions']) => {
  return Object.entries(medtechPermissions).reduce((acc: { [qePermission: string]: boolean }, [permission, value]) => {
    if (permission in ManageablePermissionsEnumSchema.enum) {
      acc[permission] = value;
    }
    return acc;
  }, {});
};

export const isQEPermissionsChanged = (
  previousMedtechPermissions: UserV2Type['medtechPermissions'],
  updatedMedtechPermissions: UserV2Type['medtechPermissions'],
) => {
  // Only check if QE permissions changed, so strip out non-QE/unmanaged permissions
  const previousQEPermissions = filterOnlyQEPermissions(previousMedtechPermissions);
  const updatedQEPermissions = filterOnlyQEPermissions(updatedMedtechPermissions);

  return !R.equals(previousQEPermissions, updatedQEPermissions);
};

export enum EnvironmentEnum {
  PROD = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}

export type Environment = {
  isProduction: boolean;
  mtbeEndpoint: string;
  authApiEndpoint: string;
  name: string;
  loginUrl: string;
  authSuccessRefreshIntervalMillis: number;
  umEndpoint: string;
  mtfeEndpoint: string;
  ldClientSideId: string;
};

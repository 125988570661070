import { environment } from '../environments/environment';

/**
 * @description Uses the window.location redirect method to completely change the current url
 * @param location full url to redirect to
 */
const redirect = (location = environment.loginUrl) => {
  window.location.replace(location);
};

export default redirect;

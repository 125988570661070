import React, { useCallback } from 'react';
import { QButtonGroup, QButton } from '@qualio/ui-components';
import * as DisplayStrings from './__displayStrings__';
import { User } from '../../../types';

type StateSetterFunction<T> = React.Dispatch<React.SetStateAction<T>>;

type UserActionButtonsProps = {
  areUsersSelected: boolean;
  currentlySelectedUsers: User[];
  setInviteModalOpen: StateSetterFunction<boolean>;
  setRoleModalOpen: StateSetterFunction<boolean>;
  setAddGroupModalOpen: StateSetterFunction<boolean>;
  setRemoveUserGroupsModalOpen: StateSetterFunction<boolean>;
};

const doesUserHaveGroups = (selectedUsers: User[]) => selectedUsers.some((user) => user.groups.length > 0);

export const UserActionButtons = ({
  currentlySelectedUsers,
  areUsersSelected,
  setRoleModalOpen,
  setAddGroupModalOpen,
  setRemoveUserGroupsModalOpen,
  setInviteModalOpen,
}: UserActionButtonsProps) => {
  const openRoleModal = useCallback(() => setRoleModalOpen(true), []);
  const openAddUserGroupModal = useCallback(() => setAddGroupModalOpen(true), []);
  const openRemoveUserGroupsModal = useCallback(() => setRemoveUserGroupsModalOpen(true), []);
  const openInviteModal = useCallback(() => setInviteModalOpen(true), []);

  return (
    <QButtonGroup>
      <QButton
        type="button"
        aria-label={DisplayStrings.SetRole}
        isDisabled={!areUsersSelected}
        variant="outline"
        data-cy="set-role"
        onClick={openRoleModal}
      >
        {DisplayStrings.SetRole}
      </QButton>
      <QButton
        type="button"
        isDisabled={!areUsersSelected}
        variant="outline"
        data-cy="add-to-group"
        aria-label="Add to group"
        onClick={openAddUserGroupModal}
      >
        {DisplayStrings.AddToGroup(currentlySelectedUsers.length)}
      </QButton>
      <QButton
        type="button"
        isDisabled={!doesUserHaveGroups(currentlySelectedUsers) || !areUsersSelected}
        variant="outline"
        data-cy="remove-from-group"
        aria-label="Remove from group"
        onClick={openRemoveUserGroupsModal}
      >
        {DisplayStrings.RemoveFromGroup(currentlySelectedUsers.length)}
      </QButton>
      <QButton
        data-metrics="um-invite-button"
        type="button"
        aria-label={DisplayStrings.InviteUser}
        data-cy="invite-user"
        data-intercom-target="invite-user"
        onClick={openInviteModal}
      >
        {DisplayStrings.InviteUser}
      </QButton>
    </QButtonGroup>
  );
};

import React, { ReactElement } from 'react';
import { QButton, QHeading, QIconButton, QSelect, QSelectItem } from '@qualio/ui-components';
import { BulkQSelect, BulkSelectCloseButton, BulkSelectContainer } from '../../../../styles/BulkSelect.styles';
import { CommonProps } from '../../types';
import * as DisplayStrings from './__displayStrings__/BulkSelect';

type BulkSelectProps = Pick<CommonProps, 'incrementStep' | 'users'> & {
  allItemsAssigned: boolean;
  clearSelected: () => void;
  selected: Set<number>;
  updateOwnerOfSelectedItems: (newOwner: QSelectItem) => void;
};

// BulkSelect component is used to reassign multiple entities at once
const BulkSelect = ({
  allItemsAssigned,
  clearSelected,
  incrementStep,
  selected: items,
  users,
  updateOwnerOfSelectedItems,
}: BulkSelectProps): ReactElement => {
  return (
    <BulkSelectContainer>
      <QHeading size="xs">{DisplayStrings.headerText(items.size)}</QHeading>
      <BulkQSelect>
        <QSelect
          data-cy="bulk-select"
          aria-label="bulk_select"
          options={users}
          onChange={(item) => item && updateOwnerOfSelectedItems(item)}
        />
      </BulkQSelect>
      <QButton
        data-cy="save-button"
        isDisabled={!allItemsAssigned}
        type="submit"
        variant="outline"
        onClick={incrementStep}
      >
        {DisplayStrings.saveText}
      </QButton>
      <BulkSelectCloseButton>
        <QIconButton aria-label="close_bulk_select" iconName="X" size="lg" onClick={clearSelected} />
      </BulkSelectCloseButton>
    </BulkSelectContainer>
  );
};

export default BulkSelect;

import { QStack } from '@qualio/ui-components';
import styled from 'styled-components';

export const RemoveUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const QStackContainer = styled(QStack)`
  min-height: calc(100vh - 500px);
`;

import React from 'react';
import { QCheckbox } from '@qualio/ui-components';
import { StopPropagation } from '../../../utils/events';

type HandleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

export const buildColumns = (areAllVisibleUsersSelected: boolean, handleHeaderCheckboxChange: HandleCheckboxChange) => [
  {
    Header: (
      <QCheckbox
        data-cy="table-checkbox"
        aria-label="table_checkbox"
        isChecked={areAllVisibleUsersSelected}
        onChange={handleHeaderCheckboxChange}
        onClick={StopPropagation}
      />
    ),
    width: '2%',
    accessor: 'checkbox',
    disableSortBy: true,
  },
  {
    Header: 'Full name',
    width: '15%',
    accessor: 'fullName',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '18%',
  },
  {
    Header: 'Role',
    accessor: 'role',
    width: '5%',
  },
  {
    Header: 'Groups',
    accessor: 'groups',
    width: '15%',
    disableSortBy: true,
  },
  {
    Header: 'Access',
    accessor: 'access',
    width: '5%',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'actions',
    width: '5%',
    disableSortBy: true,
  },
];

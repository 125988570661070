import React, { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { QButton, QButtonGroup, QStepper, QStepperPanelActions } from '@qualio/ui-components';
import { UserAssociations } from '../../types';
import Steps from './Steps';
import * as DisplayStrings from './__displayStrings__';
import { CommonProps } from './types';
import { AllEntitiesInMapReassigned } from './utils';
import { initializeState, reducer } from './state';
import SubmitHandler from './SubmitHandler';

type ReassignQualioEntitiesProps = Pick<CommonProps, 'users' | 'groupMap' | 'onSubmit'> & {
  itemsToReassign: UserAssociations;
  onCancel: () => void;
};

// Main Entry point for the stepper that can reassign Qualio Entities (docs and items) to new users
const ReassignQualioEntities = ({
  users,
  itemsToReassign,
  groupMap,
  onCancel,
  onSubmit,
}: ReassignQualioEntitiesProps): ReactElement => {
  const [entityState, dispatch] = useReducer(reducer, itemsToReassign, initializeState);
  const [step, setStep] = useState(0);

  const [showNextButton, setShowNextButton] = useState(true);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [removeButtonEnabled, setRemoveButtonEnabled] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const decrementStep = useCallback(() => setStep((stepNumber: number) => stepNumber - 1), []);
  const incrementStep = useCallback(() => setStep((stepNumber: number) => stepNumber + 1), []);

  const {
    owner: ownerItems,
    changeRequestOwner: changeRequestOwnerItems,
    approver: approverItems,
    reviewer: reviewerItems,
    issues: issueItems,
    tasks: taskItems,
    default_owner: defaultOwnerItems,
  } = entityState;

  useEffect(() => {
    const removeButtonStatus =
      AllEntitiesInMapReassigned(ownerItems) &&
      AllEntitiesInMapReassigned(changeRequestOwnerItems) &&
      AllEntitiesInMapReassigned(approverItems) &&
      AllEntitiesInMapReassigned(reviewerItems) &&
      AllEntitiesInMapReassigned(issueItems) &&
      AllEntitiesInMapReassigned(taskItems) &&
      AllEntitiesInMapReassigned(defaultOwnerItems);

    setRemoveButtonEnabled(removeButtonStatus);
  }, [ownerItems, changeRequestOwnerItems, approverItems, reviewerItems, issueItems, taskItems, defaultOwnerItems]);

  return (
    <QStepper activeStep={step} onStepItemClicked={(stepNumber: number) => setStep(stepNumber)}>
      <SubmitHandler
        users={users}
        groupMap={groupMap}
        submitInProgress={submitInProgress}
        setSubmitInProgress={setSubmitInProgress}
        entityState={entityState}
        onSubmit={onSubmit}
      />
      <Steps
        users={users}
        setShowNextButton={setShowNextButton}
        setNextButtonEnabled={setNextButtonEnabled}
        incrementStep={incrementStep}
        entityState={entityState}
        dispatch={dispatch}
      />
      <QStepperPanelActions>
        <QButton data-cy="cancel-button" onClick={onCancel} variant="ghost">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton
          data-cy="previous-button"
          isDisabled={step === 0}
          onClick={decrementStep}
          leftIcon="ArrowLeft"
          variant="outline"
        >
          {DisplayStrings.Previous}
        </QButton>
        <QButtonGroup>
          {showNextButton ? (
            <QButton
              data-cy="next-button"
              aria-label="next_button"
              isDisabled={!nextButtonEnabled}
              onClick={incrementStep}
              rightIcon="ArrowRight"
            >
              {DisplayStrings.Next}
            </QButton>
          ) : (
            <QButton
              data-cy="remove-user-button"
              type="button"
              isDisabled={!removeButtonEnabled}
              isLoading={submitInProgress}
              isDestructive
              onClick={() => setSubmitInProgress(true)}
            >
              {DisplayStrings.RemoveUser}
            </QButton>
          )}
        </QButtonGroup>
      </QStepperPanelActions>
    </QStepper>
  );
};

export default ReassignQualioEntities;

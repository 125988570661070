import { QBox, QText } from '@qualio/ui-components';
import React from 'react';

export const UnauthorizedView: React.FC = () => {
  return (
    <QBox p="5" textAlign="center">
      <QText fontSize="3xl">Unauthorized</QText>
      <div>
        <QText pb="2" fontWeight="bold">
          We&apos;re sorry!
        </QText>
        <QText pb="2">It seems you don&apos;t have access to this page.</QText>
        <QText>
          If you think this is an error, please <strong>reach out to Customer Success</strong>.
        </QText>
      </div>
    </QBox>
  );
};
